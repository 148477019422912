import sls from 'react-secure-storage';
const displayApiErrorMaps = new Map();

export const logout = ({displayAlert = false, msal = null} = {})=>{
  if (process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'uat') {
    console.error('Dev Error!!!!');
    return;
  }

  clearTpppStorages();

  // for back-office
  if (window.location.pathname.includes('back-office')) {
    window.location.href = process.env.PUBLIC_URL + '/back-office/logout';
    return;
  }

  // for user
  return window.location.href = process.env.REACT_APP_HR_MOBILE_URL + '/service?autoRedirectToTPPP=true';
};

export const displayApiError = () =>{
  const isShowing = displayApiErrorMaps.get('displayApiErrorMaps');

  if (isShowing) return;

  displayApiErrorMaps.set('displayApiErrorMaps');

  return logout();
};

export const clearTpppStorages = ()=>{
  localStorage.removeItem('accessToken');
  sls.removeItem('slsHrMobileData');
  sls.removeItem('slsHrMobileLanguage');
  sls.removeItem('version');
};

export const clearAllStorages = ()=>{
  localStorage.clear();
  sls.clear();
};
