// src/store/store.js
import {configureStore} from '@reduxjs/toolkit';
import appReducer from './appSlice';
import cartItemsReducer from './slices/cartItemsSlice';
import authReducer from './slices/authSlice';

const store = configureStore({
  reducer: {
    app: appReducer,
    cartItems: cartItemsReducer,
    auth: authReducer,
  },
});

export default store;
