import CryptoJS from 'crypto-js';

export const generateEncryptedData = ()=>{
  const dataToEncrypt = {
    authUser: {
      employeeId: '21996',
      // name: 'natthakit singkhan',
    },
    language: 'en',
  };

  const dataToEncryptString = JSON.stringify(dataToEncrypt);
  const secretKey = '79724445cc344a25e891131cc015293bf173a010e451026d0f5bbba7fca0f37e';
  const encryptedData = CryptoJS.AES.encrypt(dataToEncryptString, secretKey).toString();
  console.log('normal encryptedData', encryptedData);

  return encryptedData;
};
