import {useMemo, useState} from 'react';
import {setFullLoading} from '../../store/appSlice';
import {useDispatch} from 'react-redux';
import MaterialReactTable from 'material-react-table';
import {Col, Row} from 'react-bootstrap';
import Switch from 'react-switch';
import {trackPromise} from 'react-promise-tracker';
import {apiGet, apiPost} from '../../apis/index';
import {useMsal} from '@azure/msal-react';

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import {dataTableSettings} from '../../helpers/data-table-settings';
import {GoSync} from 'react-icons/go';
import Swal from 'sweetalert2';
import {ProductLazyLoadImg} from '../../components/user/ProductImg';
import {EnlargeProductImg} from '../../components/EnlargeProductImg';

const Render = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [enlargeProduct, setEnlargeProduct] = useState();

  const columns = useMemo(
      () => [
        {
          header: 'Image',
          accessorFn: (rowData) => {
            return <div onClick={() => {
              setEnlargeProduct(rowData);
              setShowModal(true);
            }} className={'w-100 pointer'} >
              <ProductLazyLoadImg className={'w-100'} imgId={rowData?.image} />
            </div>;
          },
          size: 0,
        },
        {
          accessorKey: 'sku',
          header: 'Sku',
          size: 0,
        },
        {
          accessorKey: 'productName',
          header: 'ProductName',
          size: 0,
        },
        {
          accessorKey: 'size',
          header: 'Size',
          size: 0,
        },
        {
          header: 'Price',
          size: 0,
          accessorFn: (rowData) => {
            return (rowData?.price ? rowData?.price?.toLocaleString('en-US') : 0) + ' ฿';
          },
        },
        {
          accessorKey: 'stock',
          header: 'Stock',
          size: 0,
        },
        {
          header: 'Status',
          size: 0,
          accessorFn: (rowData) => {
            return <Switch
              checked={(rowData?.status == '1')?true:false}
              onChange={async () => await callUpdateApi({sku: rowData?.sku, status: rowData?.status})}
              onColor="#fee0cc"
              onHandleColor="#fead77"
              handleDiameter={20}
              uncheckedIcon={false}
              checkedIcon={false}
              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
              activeBoxShadow="0px 0px 1px 1px rgba(0, 0, 0, 0.2)"
              height={10}
              width={30}
              className="react-switch"
              id="material-switch"
            />;
          },
        },
      ],
      [],
  );

  const [product, setProduct] = useState();
  const [globalFilter, setGlobalFilter] = useState('');
  const {instance, accounts} = useMsal();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const {data, isError, isFetching, isLoading, refetch} = useQuery({
    queryKey: [
      'table-data',
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
    ],
    queryFn: async () => {
      try {
        dispatch(setFullLoading(true));

        let payload = {
          offset: pagination.pageIndex * pagination.pageSize,
          limit: pagination.pageSize,
        };

        if (globalFilter) {
          payload = {
            ...payload,
            keyword: globalFilter,
          };
        }

        const json = await apiPost({
          uri: `Admin/product/lists`,
          payload: payload,
          msal: {
            instance: instance,
            accounts: accounts,
          },
        });

        setProduct(json);
        dispatch(setFullLoading(false));

        return json;
      } catch (error) {
        dispatch(setFullLoading(true));
        console.error('queryFn error', error);
      }
    },
  });

  const callUpdateApi = async ({sku = '', status=''} = {}) =>{
    if (status == '1') {
      status = 0;
    } else {
      status = 1;
    }

    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));
            await apiGet({
              uri: `Admin/product/updatestatus?sku=${sku}&status=${status}`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
            });

            refetch();
            dispatch(setFullLoading(false));
          } catch (error) {
            dispatch(setFullLoading(true));
            console.error(error);
          }
        })(),
    );
  };

  const syncProducts = async ()=>{
    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            await apiPost({
              uri: `Products/importproducts`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
              displayCustomError: true,
            });

            window.location.reload();
          } catch (error) {
            dispatch(setFullLoading(false));

            Swal.fire({
              title: 'Warning!',
              text: 'No data to sync!',
              icon: 'warning',
              confirmButtonText: 'OK',
            });
          }
        })(),
    );
  };

  const syncPrices = async ()=>{
    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            await apiPost({
              uri: `Products/updateprice`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
              displayCustomError: true,
            });

            window.location.reload();
          } catch (error) {
            dispatch(setFullLoading(false));

            Swal.fire({
              title: 'Warning!',
              text: 'No data to sync!',
              icon: 'warning',
              confirmButtonText: 'OK',
            });
          }
        })(),
    );
  };

  const syncStocks = async ()=>{
    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            await apiPost({
              uri: `Products/updatestock`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
              displayCustomError: true,
            });

            window.location.reload();
          } catch (error) {
            dispatch(setFullLoading(false));

            Swal.fire({
              title: 'Warning!',
              text: 'No data to sync!',
              icon: 'warning',
              confirmButtonText: 'OK',
            });
          }
        })(),
    );
  };

  return (
    <>
      <EnlargeProductImg showModal={showModal} setShowModal={setShowModal} product={enlargeProduct} setEnlargeProduct={setEnlargeProduct} />

      <Row className=' px-0 pb-5 pt-1'>
        <Col className='col-12 mb-4 mt-0'>
          <Row className=''>
            <Col className="col">
            </Col>
            <Col className="col-2">
              <div className='admin-btn admin-btn-primary w-100 d-flex align-items-center justify-content-center'
                onClick={async () => await syncProducts()}>
                <GoSync size={'20px'} className='me-2'/>Sync Products
              </div>
            </Col>
            <Col className="col-2">
              <div className='admin-btn admin-btn-primary w-100 d-flex align-items-center justify-content-center'
                onClick={async () => await syncPrices()}>
                <GoSync size={'20px'} className='me-2'/>Sync Prices
              </div>
            </Col>
            <Col className="col-2">
              <div className='admin-btn admin-btn-primary w-100 d-flex align-items-center justify-content-center'
                onClick={async () => await syncStocks()}>
                <GoSync size={'20px'} className='me-2'/>Sync Stocks
              </div>
            </Col>
          </Row>

        </Col>
        <Col className='col-12'>

          <MaterialReactTable
            muiTablePaginationProps={{
              rowsPerPageOptions: [
                {label: '5', value: 5},
                {label: '10', value: 10},
                {label: 'all', value: data?.total || 0},
              ],
            }}
            columns={columns}
            data={product?.products || []}
            initialState={{showGlobalFilter: true}}
            enableColumnFilters={false}
            enableSorting={false}
            manualFiltering
            manualPagination
            manualSorting
            muiToolbarAlertBannerProps={
                  isError ?
                    {
                      color: 'error',
                      children: 'Error loading data',
                    } :
                    undefined
            }
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={setPagination}
            muiSearchTextFieldProps={{
              placeholder: 'Search SKU Number',
            }}
            rowCount={product?.total || 0}
            state={{
              globalFilter,
              isLoading,
              pagination,
              showAlertBanner: isError,
              showProgressBars: isFetching,
            }}
          />
        </Col>
      </Row>

    </>
  );
};

const queryClient = new QueryClient(dataTableSettings);
const Product = () => (
  <QueryClientProvider client={queryClient}>
    <Render />
  </QueryClientProvider>
);

export default Product;
