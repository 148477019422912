
export const status = {
  inactive: 0,
  active: 1,
};

export const statuses = [
  {
    key: status.inactive,
    displayLabel: 'Inactive',
  },
  {
    key: status.active,
    displayLabel: 'Active',
  },
];
