export const paymentMethod = {
  promptPay: 0,
  banking: 1,
};

export const orderStatus = {
  toPay: 1,
  paid: 2,
  pickupReady: 3,
  closed: 4,
  cancelled: 5,
  timeout: 6,
  paymentFailed: 7,
  shippingInTransit: 8,
  pending: 9,
};

export const rePayOrderStatuses = [orderStatus?.toPay, orderStatus?.timeout, orderStatus?.paymentFailed];

export const orderStatusMappings = [
  {
    key: orderStatus?.toPay,
    color: '#ecc407',
    displayLabel: {
      en: 'Waiting for payment',
      th: 'รอการชำระเงิน',
    },
    badge: 'order-status-waiting',
    summaryClass: 'summary-status-to-pay',
  },
  {
    key: orderStatus?.paid,
    color: '#0d6efd',
    displayLabel: {
      en: 'Paid',
      th: 'ชำระเงินแล้ว',
    },
    badge: 'order-status-paid',
    summaryClass: 'summary-status-paid',
  },
  {
    key: orderStatus?.pickupReady,
    color: '#9eb356',
    displayLabel: {
      en: 'Ready for pickup',
      th: 'สินค้าพร้อม',
    },
    badge: 'order-status-pickup',
    summaryClass: 'summary-status-to-pick',
  },
  {
    key: orderStatus?.closed,
    color: '#b7b8b9',
    displayLabel: {
      en: 'Closed',
      th: 'เสร็จสิ้น',
    },
    badge: 'order-status-closed',
    summaryClass: 'summary-status-closed',
  },
  {
    key: orderStatus?.cancelled,
    color: '#f97c89',
    displayLabel: {
      en: 'Cancelled',
      th: 'ยกเลิก',
    },
    badge: 'order-status-cancelled',
    summaryClass: 'summary-status-cancelled',
  },
  {
    key: orderStatus?.timeout,
    color: '#d63384',
    displayLabel: {
      en: 'Time out',
      th: 'หมดเวลา',
    },
    badge: 'order-status-expired',
    summaryClass: 'summary-status-timeout',
  },
  {
    key: orderStatus?.paymentFailed,
    color: '#f97c89',
    displayLabel: {
      en: 'Payment failed',
      th: 'ชำระเงินไม่สำเร็จ',
    },
    badge: 'order-status-payment-failed',
    summaryClass: 'summary-status-payment-failed',
  },
  {
    key: orderStatus?.shippingInTransit,
    color: '#EE6352',
    displayLabel: {
      en: 'Shipping in transit',
      th: 'อยู่ระหว่างจัดส่ง',
    },
    badge: 'order-status-shipping-in-transit',
    summaryClass: 'summary-status-shipping-in-transit',
  },
  {
    key: orderStatus?.pending,
    color: '#FAAB78',
    displayLabel: {
      en: 'Pending',
      th: 'รอดำเนินการ',
    },
    badge: 'order-status-pending',
    summaryClass: 'summary-status-pending',
  },
];
