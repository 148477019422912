import {Col, Row} from 'react-bootstrap';
import {LuShoppingBag} from 'react-icons/lu';
import {useEffect, useState} from 'react';
import {apiPost} from '../../apis/index';
import '../../styles/back-office/sale-order-detail.scss';
import {useMsal} from '@azure/msal-react';
import Swal from 'sweetalert2';
import {orderStatus, orderStatusMappings} from '../../types/order';
import {MdArrowBackIosNew} from 'react-icons/md';
import {ProductLazyLoadImg} from '../user/ProductImg';
import {EnlargeProductImg} from '../EnlargeProductImg';
import {TbProgressAlert} from 'react-icons/tb';
import {formatDateTime} from '../../helpers/datetime';

export const PopDetail = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const {instance, accounts} = useMsal();

  const receive = () => {
    Swal.fire({
      title: 'ยืนยัน!',
      text: 'คุณแน่ใจหรือว่าจะดำเนินการนี้?',
      icon: 'warning',
      confirmButtonText: 'ยืนยัน',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      allowOutsideClick: false,
      allowEscapeKey: false,
      focusCancel: true,
    }).then(async (result) =>{
      if (result.isConfirmed) {
        const payload = {
          employeeId: props?.profileInfo?.employeeId,
          orderNumber: props?.orderDetail?.orderNumber,
        };

        const json = await apiPost({
          uri: `Pop/pop/receiving`,
          payload: payload,
          msal: {
            instance: instance,
            accounts: accounts,
          },
        });

        if (json) {
          Swal.fire({
            icon: 'success',
            title: 'บันทึกข้อมูลเรียบร้อย',
            text: 'ดำเนินการรายการคำสั่งซื้อเรียบร้อยแล้ว',
            type: 'success',
          }).then((result) => {
            props?.setPopDetail(null);
          });
        }
      }
    });
  };

  const pending = async () => {
    const {value: text} = await Swal.fire({
      text: 'คุณแน่ใจหรือว่าจะดำเนินการนี้?',
      icon: 'warning',
      input: 'textarea',
      inputPlaceholder: 'Type your message here...',
      inputAttributes: {
        'aria-label': 'Type your message here',
      },
      confirmButtonText: 'ยืนยัน',
      showCancelButton: true,
      cancelButtonText: 'ยกเลิก',
      allowOutsideClick: false,
      allowEscapeKey: false,
      inputValidator: (value) => {
        if (!value) {
          return 'Enter message!';
        }
      },
    });

    if (text) {
      const json = await apiPost({
        uri: `Order/UpdateStatus`,
        payload: {
          'orderNumber': props?.orderDetail?.orderNumber,
          'orderStatusId': orderStatus?.pending,
          'note': text,
        },
        msal: {
          instance: instance,
          accounts: accounts,
        },
      });

      if (json) {
        Swal.fire({
          icon: 'success',
          title: 'บันทึกข้อมูลเรียบร้อย',
          text: 'ดำเนินการรายการคำสั่งซื้อเรียบร้อยแล้ว',
          type: 'success',
        }).then((result) => {
          props?.setPopDetail(null);
        });
      }
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [enlargeProduct, setEnlargeProduct] = useState();

  return (
    <>
      <EnlargeProductImg showModal={showModal} setShowModal={setShowModal} product={enlargeProduct} setEnlargeProduct={setEnlargeProduct} />

      <div className='sale-order-detail' >
        <div className='mb-4 d-flex align-items-center'>
          <div className='pointer' onClick={() => props?.setPopDetail(null)}>
            <MdArrowBackIosNew size={'20px'} />
            <span className='ms-2'>รายการสั่งซื้อ</span>
          </div>
        </div>
        <Row className='m-0'>
          <Col className='col-9 custom-card p-4 me-3 left-panel'>
            <Row>
              <Col className='col-6 text-start pb-2'>
                <div className="attrTitle">เลขที่คำสั่งซื้อ </div> <div className='attrValue'>{props?.orderDetail?.orderNumber}</div>
              </Col>
              <Col className='col-6 text-end'>
                <div className="attrTitle">วันที่สั่งซื้อ </div> <div className='attrValue'>{
                  formatDateTime({
                    dateTime: props?.orderDetail?.createdAt,
                  })
                }</div>
              </Col>
            </Row>

            <table className="table table-borderless table-hover  mt-4 orderDetai-tatle">
              <thead>
                <tr className='table-cart text-uppercase '>
                  <th scope="col" className='font-normal'>Image</th>
                  <th scope="col" className='font-normal ps-3'>รหัสสินค้า</th>
                  <th scope="col" className='font-normal'>ขนาด(Size)</th>
                  <th scope="col" className='font-normal'>จำนวน</th>
                  <th scope="col" className='font-normal text-end pe-3'>ราคา</th>
                </tr>
              </thead>
              <tbody>
                {props?.orderDetail?.orderDetails?.map((v, i) => (
                  <tr key={i} className='pointer' onClick={() => {
                    setEnlargeProduct(v);
                    setShowModal(true);
                  }}>
                    <td className='w-10'>
                      <ProductLazyLoadImg className={'w-100'} imgId={v?.image} />
                    </td>
                    <td className='ps-3'>
                      <div>{v?.sku}</div>
                    </td>
                    <td>{v?.size || 'N/A'}</td>
                    <td>{v?.qty || 0}</td>
                    <td className='text-end pe-3'>{v?.price?.toLocaleString('en-US') || 0} ฿</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Row className='mt-2'>
              <Col xs={8}>

              </Col>
              <Col xs={4} >
                <div className='fw-bold'>
                  <p>Payment: <span className='float-right'>{props?.orderHistory?.chargesPayment?.qrPromtpay ? 'PromptPay' : 'Banking'}</span></p>
                  <p>ราคารวม: <span className='float-right'>{props?.orderDetail?.total?.toLocaleString('en-US') || 0} ฿</span></p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className='col custom-card p-4 right-panel'>
            {(props?.orderDetail?.orderStatusId === orderStatus.pickupReady)?(
            <>
              <Row className='p-2 m-2'>
                <div className='admin-btn admin-btn-receive w-100' onClick={receive}><LuShoppingBag size={'26px'} className='me-2' /> <span>รับสินค้า</span>
                </div>
              </Row>
              <Row className='p-2 m-2'>
                <div className='admin-btn admin-btn-receive bg-danger w-100' onClick={pending}><TbProgressAlert size={'26px'} className='me-2' /> <span>รอดำเนินการ</span>
                </div>
              </Row>
            </>
           ):(<></>)}
            <Row className='p-2 mx-0 mt-3'>
              <h5>ประวัติสถานะคำสั่งซื้อ</h5>
              <ul className="timelines">
                {props?.orderDetail?.orderHistories?.sort((a, b) => a.id < b.id ? 1:-1).map((v, i) => (
                  <li key={i}>
                    <small style={{
                      color: orderStatusMappings?.find((osm) => osm.key == v?.orderStatusId)?.color,
                    }}>{orderStatusMappings?.find((osm) => osm?.key == v?.orderStatusId)?.displayLabel?.en}</small>
                    <div className="time">{formatDateTime({dateTime: v?.createdAt})}</div>
                    <div className="note">{v?.note}</div>
                  </li>
                ))}
              </ul>
            </Row>
          </Col>
        </Row>

      </div>
    </>
  );
};
