import {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {LuShoppingCart, LuLogOut, LuPackageCheck, LuPackageX} from 'react-icons/lu';
import {RiShoppingBag3Fill} from 'react-icons/ri';

const POPLeftSidebar = (props)=>{
  const [currentMenu, setCurrentMenu] = useState('');

  useEffect(() => {
    const {pathname} = window.location;
    const paths = pathname.split('/');

    setCurrentMenu(paths[4] || 'pickup-orders');
  }, []);

  return (
    <>
      <Col className={`col-2 left-sidebar ${props?.isBarOpen ? 'opened' : 'closed'}`}>
        <div className=''>

          <h1 className='left-app-name text-center'><RiShoppingBag3Fill size={'40px'} /> TPPP</h1>

          <div className="menu-wrapper">
            <a href={`${process.env.PUBLIC_URL}/back-office/pop-admin/pickup-orders`}>
              <div className={`item d-flex align-items-center ${(currentMenu == 'pickup-orders') && 'active'}`}>
                <LuPackageCheck size={'24px'} className='me-2' /> Pickup Orders
              </div>
            </a>
            <a href={`${process.env.PUBLIC_URL}/back-office/pop-admin/pending-orders`}>
              <div className={`item d-flex align-items-center ${(currentMenu == 'pending-orders') && 'active'}`}>
                <LuPackageX size={'24px'} className='me-2' /> Pending Orders
              </div>
            </a>
            <a href={`${process.env.PUBLIC_URL}/back-office/pop-admin/sale-order`}>
              <div className={`item d-flex align-items-center ${currentMenu == 'sale-order' && 'active'}`}>
                <LuShoppingCart size={'24px'} className='me-2' /> Sales Order
              </div>
            </a>
            <div className='item d-flex align-items-center' onClick={() => window.location.href = process.env.PUBLIC_URL + '/back-office/logout'}>
              <LuLogOut size={'24px'} className='me-2' /> Logout
            </div>
          </div>
        </div>

      </Col>
    </>
  );
};

export default POPLeftSidebar;
