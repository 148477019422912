/* eslint-disable new-cap */
import {useMemo, useState} from 'react';
import {setFullLoading} from '../../store/appSlice';
import {useDispatch} from 'react-redux';
import MaterialReactTable from 'material-react-table';
import {Col, Row} from 'react-bootstrap';
// import RefreshIcon from '@mui/icons-material/Refresh';
import {LuShoppingBag} from 'react-icons/lu';
import {trackPromise} from 'react-promise-tracker';
import {apiGet, apiPost} from '../../apis/index';
import {PopDetail} from '../../components/back-office/PopDetail';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import {useMsal} from '@azure/msal-react';
import {orderStatus, orderStatusMappings} from '../../types/order';
import '../../styles/back-office/pop.scss';
import {LuQrCode} from 'react-icons/lu';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import {dataTableSettings} from '../../helpers/data-table-settings';
import {GoSync} from 'react-icons/go';
import Swal from 'sweetalert2';
import {formatDateTime} from '../../helpers/datetime';

const Render = () => {
  const dispatch = useDispatch();
  const [globalFilter, setGlobalFilter] = useState('');

  const columns = useMemo(
      () => [
        {
          accessorKey: 'orderNumber',
          header: 'เลขที่คำสั่งซื้อ',
          size: 0,
        },
        {
          accessorKey: 'employeeId',
          header: 'รหัสพนักงาน',
          size: 0,
        },
        {
          accessorKey: 'total',
          header: 'จำนวนเงิน',
          size: 0,
          accessorFn: (rowData) => {
            return (rowData?.total ? rowData.total.toLocaleString('en-US') : '') + ' ฿';
          },
        },
        {
          accessorKey: 'orderStatusId',
          header: 'สถานะคำสั่งซื้อ',
          size: 0,
          accessorFn: (rowData) => {
            return (
              <span className={`badge rounded-pill ${orderStatusMappings?.find((osm) => osm?.key == rowData?.orderStatusId)?.badge}`} >
                {orderStatusMappings?.find((osm) => osm?.key == rowData?.orderStatusId)?.displayLabel?.en}
              </span>
            );
          },
        },
        {
          accessorKey: 'createdAt',
          header: 'วันที่สั่งซื้อ',
          size: 0,
          accessorFn: (rowData) => {
            return formatDateTime({
              dateTime: rowData?.updatedAt,
            });
          },
        },
        {
          header: 'รับสินค้า',
          size: 0,
          accessorFn: (rowData) => {
            return <>
              {
              (rowData?.orderStatusId=== orderStatus.pickupReady)?
              <LuShoppingBag style={{cursor: 'pointer', fontSize: '25px'}} className="recieveIcon" onClick={() => callPopDetailApi({orderNumber: rowData?.orderNumber, employeeId: rowData?.employeeId})}/>:
              <></>
              }
            </>;
          },
        },
      ],
      [],
  );

  const [popData, setPopData] = useState();
  const [popDetail, setPopDetail] = useState();
  const {instance, accounts} = useMsal();
  const [userInfo, setUserInfo] = useState();
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {isError, isFetching, isLoading} = useQuery({
    queryKey: [
      'table-data',
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      popDetail,
    ],
    queryFn: async () => {
      try {
        let payload = {
          employeeId: globalFilter,
          offset: pagination.pageIndex * pagination.pageSize,
          limit: pagination.pageSize,
        };

        if (globalFilter) {
          payload = {
            ...payload,
            keyword: globalFilter,
          };
        }

        const json = await apiPost({
          uri: `Pop/pop/lists`,
          payload: payload,
          msal: {
            instance: instance,
            accounts: accounts,
          },
          displayCustomError: true,
        });

        const hrmobileProfile = await apiGet({
          uri: `Employee/GetUserBasicInfo?emailOrId=${globalFilter}`,
          msal: {
            instance: instance,
            accounts: accounts,
          },
          callHrMobile: true,
          useHrMobileSecondaryApi: true,
          displayCustomError: true,
        });
        setUserInfo(hrmobileProfile);

        setPopData(json);

        return json;
      } catch (error) {
        setUserInfo(null);
        setPopData([]);

        return true;
      }
    },
    // keepPreviousData: true,
  });

  const callPopDetailApi = async ({orderNumber = '', employeeId = ''} = {}) =>{
    if (!employeeId) return;
    console.log('orderNumber=', orderNumber);
    console.log('employeeId=', employeeId);
    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            const getData = await apiGet({
              uri: `Pop/pop/order?employeeId=${employeeId}&orderNumber=${orderNumber}`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
            });

            setPopDetail(getData);
            dispatch(setFullLoading(false));
          } catch (error) {
            dispatch(setFullLoading(true));
            console.error(error);
          }
        })(),
    );
  };

  const syncOrders = ()=>{
    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            await apiPost({
              uri: `CronJobs/TriggerOrderStatus`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
              displayCustomError: true,
            });

            window.location.reload();
          } catch (error) {
            dispatch(setFullLoading(false));

            Swal.fire({
              title: 'Warning!',
              text: 'No data to sync!',
              icon: 'warning',
              confirmButtonText: 'OK',
            });
          }
        })(),
    );
  };

  return (
    <>
      <Row className='mb-4'>
        <Col className="col-2">
          <div className='admin-btn admin-btn-primary w-100 d-flex align-items-center justify-content-center'
            onClick={async () => await syncOrders()}>
            <GoSync size={'20px'} className='me-2'/>Sync Orders
          </div>
        </Col>

        <Col xs={6}></Col>

        <Col className='col-4'>
          <div className="input-group input-group-lg ">
            <input onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setGlobalFilter(e.target.value);
              }
            }} type="text" className="form-control searchInput" placeholder="Enter Employee ID" aria-label="Enter Employee ID" aria-describedby="basic-addon2"/>
            <span className="input-group-text" id="basic-addon2"><LuQrCode size={'32px'} /></span>
          </div>
        </Col>
      </Row>

      <Row className='mb-4'>
        <Col className="col-12">
          <Card>
            <CardContent>

              <Row className=' m-0'>
                <Col className="col-12 pb-3">
                  <Typography variant="h6" component="div"><strong>ข้อมูลพนักงาน</strong></Typography>
                </Col>
                <Col className="col-3">
                  <div className="attrTitle">รหัสพนักงาน</div>
                  <div className='attrValue'>{userInfo?.employeeId}</div>
                </Col>
                <Col className="col-3">
                  <div className="attrTitle">ชื่อพนักงาน</div>
                  <div className='attrValue'>{userInfo?.employeeNameEn}</div>
                </Col>
                <Col className="col-3">
                  <div className="attrTitle">อีเมล์</div>
                  <div className='attrValue'>{userInfo?.email}</div>
                </Col>
                <Col className="col-3">
                  <div className="attrTitle">เบอร์โทรศัพท์</div>
                  <div className='attrValue'>{userInfo?.mobileNo}</div>
                </Col>
              </Row>
            </CardContent>
          </Card>
        </Col>
      </Row>
      {popDetail ?
      (
        <PopDetail orderDetail={popDetail} setPopDetail={setPopDetail} profileInfo={userInfo}/>
      ) : (
       <>
         <Row className=' pb-2 pt-1'>
           <Col className='col-12 orderLists'>
             <MaterialReactTable
               muiTablePaginationProps={{
                 rowsPerPageOptions: [
                   {label: '5', value: 5},
                   {label: '10', value: 10},
                   {label: 'all', value: popData?.total || 0},
                 ],
               }}
               columns={columns}
               data={popData?.orders || []}
               enableGlobalFilter={false}
               enableColumnFilters={false}
               enableSorting={false}
               manualFiltering
               manualPagination
               manualSorting
               onPaginationChange={setPagination}
               muiSearchTextFieldProps={{
                 placeholder: 'Search Employee ID',
               }}
               rowCount={popData?.total || 0}
               state={{
                 globalFilter,
                 isLoading,
                 pagination,
                 showProgressBars: isFetching,
               }}
             />
           </Col>
         </Row>
       </>
      )}
    </>
  );
};

const queryClient = new QueryClient(dataTableSettings);
const Pop = () => (
  <QueryClientProvider client={queryClient}>
    <Render />
  </QueryClientProvider>
);

export default Pop;
