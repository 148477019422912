import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from '../pages/user/Home';
import PaymentQR from '../pages/user/PaymentQR';
import PostPayment from '../pages/user/PostPayment';
import OrderHistory from '../pages/user/OrderHistory';
import {useEffect, useState} from 'react';
import sls from 'react-secure-storage';

import '../styles/user/main.scss';
import {trackPromise} from 'react-promise-tracker';
import {setAppLanguage, setFullLoading} from '../store/appSlice';
import {apiGet, apiPost} from '../apis';
import {useDispatch, useSelector} from 'react-redux';
import {getCurrentLanguage} from '../helpers/language-switcher';
import {useMsal} from '@azure/msal-react';
import maintenanceAnimation from '../assets/images/maintenance-animation.json';
import Lottie from 'lottie-react';
import moment from 'moment';
import {language} from '../language';

import '../styles/fonts.scss';

export const UserRoutes = ()=> {
  const {instance, accounts} = useMsal();
  const [encryptedData, setEncryptedData] = useState();
  const dispatch = useDispatch();
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);

  useEffect(() => {
    const lang = getCurrentLanguage();

    sls.setItem('slsHrMobileLanguage', lang);
    dispatch(setAppLanguage(lang));
  }, [dispatch]);

  const callUerProfileApi = async () =>{
    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            const res = await apiPost({
              uri: 'TimeInOut/GetUserInfo',
              msal: {
                instance: instance,
                accounts: accounts,
              },
              callHrMobile: true,
              useHrMobileSecondaryApi: true,
            });

            if (res) {
              const fullEmpInfo = await apiGet({
                uri: `Employee/GetUserBasicInfo?emailOrId=${res?.employeeId}`,
                msal: {
                  instance: instance,
                  accounts: accounts,
                },
                callHrMobile: true,
                useHrMobileSecondaryApi: true,
              });

              const empData = {
                authUser: {...res, ...fullEmpInfo},
              };
              sls.setItem('slsHrMobileData', empData);
              setEncryptedData(empData);
            }

            dispatch(setFullLoading(false));
          } catch (error) {
            dispatch(setFullLoading(true));
            console.error(error);
          }
        })(),
    );
  };

  useEffect(() => {
    (async () => {
      try {
        await callUerProfileApi();
      } catch (error) {
        dispatch(setFullLoading(true));
        console.error(error);

        return false;
      }
    })();
  }, []);

  // START: display maintenance page logic
  const [displayMaintenanceAni, setDisplayMaintenanceAni] = useState(false);
  const checkInterval = 1000 * 5;
  const checkMaintenancePeriod = ()=>{
    let isMaintenancePeriod = false;
    const format = 'hh:mm:ss';
    const currentTime = moment(new Date(), format);
    const beforeTime = moment('01:00:00', format);
    const afterTime = moment('03:00:00', format);

    if (currentTime.isBetween(beforeTime, afterTime)) {
      isMaintenancePeriod = true;
    } else {
      isMaintenancePeriod = false;
    }

    return isMaintenancePeriod;
  };

  const checkServerMaintenance = async () =>{
    (async () => {
      try {
        if (process.env.REACT_APP_ENV == 'local') return;

        const res = await apiGet({
          uri: `Maintainance/Status`,
          msal: {
            instance: instance,
            accounts: accounts,
          },
          displayCustomError: true,
        });

        setDisplayMaintenanceAni(res?.maintainanceMode);
      } catch (error) {
        console.error(error);
      }
    })();
  };

  useEffect(() => {
    const isMaintenancePeriod = checkMaintenancePeriod();

    if (isMaintenancePeriod) {
      setDisplayMaintenanceAni(isMaintenancePeriod);
      return;
    }

    // auto check
    const interval = setInterval(async () => {
      await checkServerMaintenance();
    }, checkInterval);
    return () => clearInterval(interval);
  }, []);

  if (displayMaintenanceAni) {
    return (
      <div className="container">
        <div className='maintenance-page text-center fw-bold text-nowrap text-secondary'>
          <Lottie animationData={maintenanceAnimation} speed={10} loop={true} style={{maxWidth: '430px'}} />
          <p className=''>{language?.maintenanceMsg?.firstLine?.[hrMobileLanguage]}</p>
          <p className=''>{language?.maintenanceMsg?.secondLine?.[hrMobileLanguage]}</p>
        </div>
      </div>
    );
  }
  // END: display maintenance page logic

  if (!encryptedData) {
    return <></>;
  }

  return (
    <div className='container user-app pt-4'>
      <BrowserRouter>
        <Routes>
          {/* User */}
          <Route path="/" element={
            <Home />
          } />
          <Route path="/order/qr-payment" element={
            <PaymentQR />
          } />
          <Route path="/order/post-payment" element={
            <PostPayment />
          } />
          <Route path="/order-histories" element={
            <OrderHistory />
          } />
        </Routes>
      </BrowserRouter>
    </div>
  );
};
