import {useEffect, useState} from 'react';
import {Col} from 'react-bootstrap';
import {LuLayoutDashboard, LuBox, LuShoppingCart, LuSettings, LuLogOut} from 'react-icons/lu';
import {GoSync} from 'react-icons/go';

const LeftSidebar = (props)=>{
  const [currentMenu, setCurrentMenu] = useState('');

  useEffect(() => {
    const {pathname} = window.location;
    const paths = pathname.split('/');

    setCurrentMenu(paths[3] || 'dashboard');
  }, []);

  return (
    <>
      <Col className={`col-2 left-sidebar ${props?.isBarOpen ? 'opened' : 'closed'}`}>
        <div>
          <h1 className='left-app-name text-center'><LuShoppingCart size={'40px'} /> TPPP</h1>

          <div className="menu-wrapper">
            <a href={`${process.env.PUBLIC_URL}/back-office/dashboard`}>
              <div className={`item d-flex align-items-center ${(currentMenu == 'dashboard') && 'active'}`}>
                <LuLayoutDashboard size={'24px'} className='me-2' /> Dashboard
              </div>
            </a>
            <a href={`${process.env.PUBLIC_URL}/back-office/product`}>
              <div className={`item d-flex align-items-center ${currentMenu == 'product' && 'active'}`}>
                <LuBox size={'24px'} className='me-2' /> Products
              </div>
            </a>
            <a href={`${process.env.PUBLIC_URL}/back-office/sale-order`}>
              <div className={`item d-flex align-items-center ${currentMenu == 'sale-order' && 'active'}`}>
                <LuShoppingCart size={'24px'} className='me-2' /> Sales Order
              </div>
            </a>
            <a href={`${process.env.PUBLIC_URL}/back-office/sync-logs`}>
              <div className={`item d-flex align-items-center ${currentMenu == 'sync-logs' && 'active'}`}>
                <GoSync size={'22px'} className='me-2' /> Sync Logs
              </div>
            </a>
            <a href={`${process.env.PUBLIC_URL}/back-office/setting`}>
              <div className={`item d-flex align-items-center ${currentMenu == 'setting' && 'active'}`}>
                <LuSettings size={'24px'} className='me-2' /> Settings
              </div>
            </a>
            <div className='item d-flex align-items-center' onClick={() => window.location.href = process.env.PUBLIC_URL + '/back-office/logout'}>
              <LuLogOut size={'24px'} className='me-2' /> Logout
            </div>
          </div>
        </div>

      </Col>
    </>
  );
};

export default LeftSidebar;
