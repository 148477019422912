/**
 * dev ref: https://www.npmjs.com/package/countdown-js
 */

import {Col, Row} from 'react-bootstrap';
import {RxCross1} from 'react-icons/rx';
import {language} from '../../language';
import {useLocation, useNavigate} from 'react-router-dom';
import {transformDivBottomToTop} from '../../helpers/animations/common';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setFullLoading} from '../../store/appSlice';
import {usePromiseTracker} from 'react-promise-tracker';
import Countdown from 'countdown-js';
import {apiPost} from '../../apis';

import '../../styles/user/main.scss';
import '../../styles/user/payment-qr.scss';
import Swal from 'sweetalert2';
import {useMsal} from '@azure/msal-react';

const PaymentQR = () => {
  const {instance, accounts} = useMsal();
  const queryParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isFullLoading = useSelector((state) => state.app.isFullLoading);
  const {promiseInProgress} = usePromiseTracker();
  const [timerLeft, setTimerLeft] = useState('');
  const location = useLocation();
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);

  useEffect(() => {
    transformDivBottomToTop();
  }, []);

  useEffect(() => {
    dispatch(setFullLoading(false));
  }, [dispatch]);

  const maxMinute = 15;
  const defaultEndTime = new Date(new Date(queryParams.get('startedDate')).getTime() + (1000 * 60 * maxMinute));
  const [endTime, setEndTime] = useState(defaultEndTime);

  const timer = ()=>{
    Countdown.timer(endTime, function(timeLeft) {
      setTimerLeft(`${timeLeft.minutes || 0} ${language?.minutes?.[hrMobileLanguage]}, ${timeLeft.seconds || 0} ${language?.seconds?.[hrMobileLanguage]}`);
    }, function() {
      setTimerLeft('expired');
    });
  };

  useEffect(() => {
    timer();
  }, []);

  useEffect(() => {
    if (timerLeft == 'expired') return;

    let timerInterval;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setEndTime(defaultEndTime);
        clearInterval(timerInterval);
      } else {
        setEndTime(defaultEndTime);
        timerInterval = setInterval(() => {
          timer();
        }, 1000);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(timerInterval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [location, timerLeft]);

  const [isPaid, setIsPaid] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (!queryParams.get('orderNumber')) return;

    const timerInterval = setInterval(() => {
      apiPost({
        uri: `Payment/progress?OrderNumber=${queryParams.get('orderNumber')}`,
        msal: {
          instance: instance,
          accounts: accounts,
        },
      }).then((paymentRes) => {
        setIsPaid(paymentRes || false);
      }).catch((error) => console.error(error));
    }, 1000 * 5);

    return () => {
      clearInterval(timerInterval);
    };
  }, [location]);

  useEffect(() => {
    if (!isPaid) return;

    const queryParams = new URLSearchParams(window.location.search);

    navigate(`/order/post-payment?orderNumber=${queryParams.get('orderNumber')}`);
  }, [isPaid, navigate]);

  // handel expired qr
  useEffect(() => {
    if (timerLeft == 'expired') {
      Swal.fire({
        title: 'Warning',
        text: 'QR is expired!',
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: 'black',
        allowOutsideClick: false,
        allowEscapeKey: false,
      }).then(() =>{
        navigate(`/`);
      });
    }
  }, [timerLeft, navigate]);

  if (timerLeft == 'expired') {
    return <></>;
  }

  return (
    <>
      <div className="payment-qr">
        <Row>
          <Col className='col-6'>
            <span style={{cursor: 'pointer'}} onClick={() => navigate(`/`)}>
              <RxCross1 style={{fontSize: '26px'}} />
            </span>
          </Col>
          <Col className='col-6' >
          </Col>
        </Row>

        <h3 className='my-4'>{language?.homePage?.Cart?.QRPayment?.[hrMobileLanguage]}</h3>

        <>
          {!(promiseInProgress || isFullLoading) && (
            <div className='container my-5 transform-bottom-to-top'>
              <div className='d-flex align-items-center justify-content-center mb-5'>
                <img src={queryParams.get('qrUrl')} className='w-100'/>
              </div>

              <h4 className='text-center'>{language?.Timeout?.[hrMobileLanguage]}: {timerLeft || ''}</h4>

              <div className="mt-5 px-5 py-2 payment-qr-btn animated-hover-btn bg-red mt-4 float-box-shadow" onClick={() =>{
                window.location.href = queryParams.get('qrUrl');
              }}>
                <h3 className='text-white my-2 text-center w-100' >{language?.homePage?.Cart?.BtnDownload?.[hrMobileLanguage]}</h3>
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default PaymentQR;
