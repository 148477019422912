import {LazyLoadImage} from 'react-lazy-load-image-component';
import {setDefaultProductImg} from '../../helpers/default-product-img';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useState} from 'react';

export const ProductImg = (props)=>{
  return <img className={props?.className} style={{maxHeight: '150px', objectFit: 'contain'}} src={`https://libraryimageengine.azurewebsites.net/api/Image/${props?.imgId}/thumbnail`} onError={setDefaultProductImg} />;
};

export const ProductLazyLoadImg = (props)=>{
  const [isLoading, setIsLoading] = useState(true);
  const [isLargeImgLoading, setIsLargeImgLoading] = useState(true);

  if (!props?.imgId) {
    return <></>;
  }

  return (
    <>
      {isLargeImgLoading && (
        <>
          {isLoading && (
            <img className={props?.className} style={{maxHeight: '150px', objectFit: 'contain'}} src={process.env.REACT_APP_END_POINT + '/images/default-product-img.png'} onError={setDefaultProductImg} />
          )}

          <LazyLoadImage className={props?.className}
            src={`https://libraryimageengine.azurewebsites.net/api/Image/${props?.imgId}/thumbnail`}
            effect={isLoading ? props?.effect || 'blur' : null}
            wrapperProps={{
              style: {transitionDelay: '.2s'},
            }}
            style={isLoading ? {width: '0', height: '0'} : {}}
            onError={setDefaultProductImg}
            onLoad={() => setIsLoading(false)}
          />
        </>
      )}

      {/* load low quality img first and replace with high quality */}
      {props?.useLargeImg && (
        <LazyLoadImage className={props?.className}
          src={`https://libraryimageengine.azurewebsites.net/api/Image/${props?.imgId}/large`}
          wrapperProps={{
            style: {transitionDelay: '.2s'},
          }}
          style={isLargeImgLoading ? {width: '0', height: '0'} : {}}
          onError={setDefaultProductImg}
          onLoad={() => setIsLargeImgLoading(false)}
        />
      )}
    </>
  );
};
