import {useEffect, useState} from 'react';
import Slider from '@mui/material/Slider';

const PriceRangeFilter = (props) => {
  const [defaultMinPrice, setDefaultMinPrice] = useState(1);
  const [defaultMaxPrice, setDefaultMaxPrice] = useState(0);

  useEffect(() => {
    const minPrice = props?.filterMasterData?.priceRange?.minPrice || 1;
    const maxPrice = props?.filterMasterData?.priceRange?.maxPrice || 0;

    setDefaultMinPrice(minPrice);
    setDefaultMaxPrice(maxPrice);
  }, [props]);

  const handleChanges = (event, newValue) => {
    props?.setRange(newValue);
  };

  return (
    <div className='ignore-click-for-filter-popup' style = {{width: '100%', padding: '0 20px'}}>
      <Slider step={100} className='ignore-click-for-filter-popup' min={defaultMinPrice} max={defaultMaxPrice}
        value={props?.range[0] == 0 ? [defaultMinPrice, defaultMaxPrice] : props?.range} onChange = {handleChanges} valueLabelDisplay="auto"/>

      <span className='fs-12'>
        {props?.range[0] == 0 ?
          (`${defaultMinPrice?.toLocaleString('en-US')} ฿ - ${defaultMaxPrice?.toLocaleString('en-US')} ฿`) :
          (`${props?.range[0]?.toLocaleString('en-US')} ฿ - ${props?.range[1]?.toLocaleString('en-US')} ฿`)}
      </span>
    </div>
  );
};

export default PriceRangeFilter;
