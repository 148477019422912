import {useMemo, useState} from 'react';
import {setFullLoading} from '../../store/appSlice';
import {useDispatch} from 'react-redux';
import MaterialReactTable from 'material-react-table';
import {Col, Row} from 'react-bootstrap';
import {LuClipboard, LuPenSquare, LuTrash2} from 'react-icons/lu';
import {apiDelete, apiGet} from '../../apis/index';
import Badge from 'react-bootstrap/Badge';
import {useMsal} from '@azure/msal-react';
import _ from 'lodash';

import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import {dataTableSettings} from '../../helpers/data-table-settings';
import {ModifySettingModal} from '../../components/back-office/ModifySettingModal';
import {trackPromise} from 'react-promise-tracker';
import Swal from 'sweetalert2';
import {formatDateTime} from '../../helpers/datetime';

const Render = () => {
  const dispatch = useDispatch();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [settingDetail, setSettingDetail] = useState();
  const {instance, accounts} = useMsal();

  const columns = useMemo(
      () => [
        {
          header: 'No.',
          size: 0,
          accessorFn: (rowData, i) => {
            return i+1;
          },
        },
        {
          header: 'Item Limit',
          size: 0,
          accessorFn: (rowData) => {
            return rowData.limitationItem + ' items';
          },
        },
        {
          header: 'Order Export Limit',
          size: 0,
          accessorFn: (rowData) => {
            return rowData.limitationSaleOrder + ' orders';
          },
        },
        {
          accessorKey: 'status',
          header: 'Status',
          size: 0,
          accessorFn: (rowData) => {
            if (rowData.status === '1') {
              return <Badge bg="success" className='text-center' style={{width: 60}}>Active</Badge>;
            } else {
              return <Badge bg="secondary" className='text-center' style={{width: 60}}>Inactive</Badge>;
            }
          },
        },
        {
          accessorKey: 'createdBy',
          header: 'Create By',
          size: 0,
        },
        {
          accessorKey: 'createdAt',
          header: 'Create At',
          size: 0,
          accessorFn: (rowData) => {
            return formatDateTime({
              dateTime: rowData.createdAt,
            });
          },
        },
        {
          accessorKey: 'updatedBy',
          header: 'Update By',
          size: 0,
        },
        {
          accessorKey: 'updatedAt',
          header: 'Update At',
          size: 0,
          accessorFn: (rowData) => {
            return formatDateTime({
              dateTime: rowData.updatedAt,
            });
          },
        },
        {
          header: 'Action',
          size: 0,
          accessorFn: (rowData) => {
            return <>
              <LuPenSquare style={{cursor: 'pointer', fontSize: '20px', color: '#7367f0'}} className='me-3' onClick={() => {
                setSettingDetail(rowData);
                setShowCreateModal(true);
              }}/>
              <LuTrash2 style={{cursor: 'pointer', fontSize: '20px', color: 'red'}} onClick={() => deleteSetting(rowData)}/>
            </>;
          },
        },
      ],
      [],
  );

  const [settings, setSettings] = useState();
  const [globalFilter, setGlobalFilter] = useState('');

  const {isError, isFetching, isLoading, refetch} = useQuery({
    queryKey: [
      'table-data',
      globalFilter,
      showCreateModal,
    ],
    queryFn: async () => {
      try {
        dispatch(setFullLoading(true));

        let json = await apiGet({
          uri: `Admin/limitation/lists`,
          msal: {
            instance: instance,
            accounts: accounts,
          },
        });

        json = _.orderBy(json, ['updatedAt'], ['desc']);

        setSettings(json);
        dispatch(setFullLoading(false));
        return json;
      } catch (error) {
        dispatch(setFullLoading(true));
        console.error('queryFn error', error);
      }
    },
    // keepPreviousData: true,
  });

  const deleteSetting = async (setting)=>{
    Swal.fire({
      title: 'Warning!',
      text: 'Are you sure to delete?',
      icon: 'warning',
      confirmButtonText: 'Yes',
      confirmButtonColor: '#F97C89',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      allowOutsideClick: false,
      allowEscapeKey: false,
      focusCancel: true,
    }).then(async (result) =>{
      if (result.isConfirmed) {
        trackPromise(
            (async () => {
              try {
                dispatch(setFullLoading(true));
                const payload = setting;

                await apiDelete({
                  uri: `Admin/limitation/delete`,
                  payload,
                  msal: {
                    instance: instance,
                    accounts: accounts,
                  },
                });

                refetch();
                dispatch(setFullLoading(false));
              } catch (error) {
                dispatch(setFullLoading(true));
                console.error(error);
              }
            })(),
        );
      }
    });
  };

  return (
    <>
      <ModifySettingModal
        show={showCreateModal}
        onHide={() => setShowCreateModal(false)}
        settingDetail={settingDetail}
        setSettingDetail={setSettingDetail}
      />

      <Row className=' pb-5 pt-1'>
        <Col className='col-12 mb-4 mt-0'>
          <Row className=''>
            <Col className="col-7">
            </Col>
            <Col className="col-3">

            </Col>
            <Col className="col-2">
              <div className='admin-btn admin-btn-primary w-100 d-flex align-items-center justify-content-center' style={{cursor: 'pointer'}}
                onClick={() => setShowCreateModal(true)}
              >
                <LuClipboard size={'20px'} className='me-2'/>Create
              </div>
            </Col>
          </Row>

        </Col>
        <Col className='col-12'>
          <MaterialReactTable
            enablePagination={false}
            enableBottomToolbar={false}
            columns={columns}
            data={settings || []}
            enableColumnFilters={false}
            enableSorting={false}
            manualFiltering
            manualPagination
            manualSorting
            muiToolbarAlertBannerProps={
                  isError ?
                    {
                      color: 'error',
                      children: 'Error loading data',
                    } :
                    undefined
            }
            onGlobalFilterChange={setGlobalFilter}
            rowCount={settings?.total || 0}
            state={{
              globalFilter,
              isLoading,
              showAlertBanner: isError,
              showProgressBars: isFetching,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

const queryClient = new QueryClient(dataTableSettings);
const Setting = () => (
  <QueryClientProvider client={queryClient}>
    <Render />
  </QueryClientProvider>
);

export default Setting;
