import {Col, Row} from 'react-bootstrap';
import {RxCross1} from 'react-icons/rx';
import {language} from '../../language';
import '../../styles/user/main.scss';
import '../../styles/user/order-history.scss';
import {apiGet} from '../../apis';
import {trackPromise, usePromiseTracker} from 'react-promise-tracker';
import {useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import OrderHistoryDetail from './OrderHistoryDetail';
import {orderStatusMappings} from '../../types/order';
import sls from 'react-secure-storage';
import {useMsal} from '@azure/msal-react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import Lottie from 'lottie-react';
import dataNotFound from '../../assets/images/not-found-item.json';
import {formatDateTime} from '../../helpers/datetime';

const OrderHistory = () => {
  const {instance, accounts} = useMsal();
  const [currentOrder, setCurrentOrder] = useState();
  const [showHistories, setShowHistories] = useState(true);
  const isFullLoading = useSelector((state) => state.app.isFullLoading);
  const {promiseInProgress} = usePromiseTracker();
  const slsHrMobileData = sls.getItem('slsHrMobileData');
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);
  const navigate = useNavigate();
  const [orderHistories, setOrderHistories] = useState();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!orderHistories?.length || !searchParams.get('orderNumber')) return;

    const order = orderHistories?.find((v) => v == searchParams.get('orderNumber'));
    setCurrentOrder(order);
    setShowHistories(false);
  }, [searchParams, orderHistories]);

  useEffect(() => {
    if (!slsHrMobileData?.authUser?.employeeId) return;

    trackPromise(
        (async () => {
          try {
            const getHistories = await apiGet({
              uri: `Order/history?employeeId=${slsHrMobileData?.authUser?.employeeId || ''}`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
            });

            setOrderHistories(getHistories);
          } catch (error) {
            console.error(error);
          }
        })(),
    );
  }, [instance, accounts, slsHrMobileData?.authUser?.employeeId]);

  return (
    <>
      {showHistories ? (
        <div className="order-history">
          <Row>
            <Col className='col-6'>
              <span style={{cursor: 'pointer'}} onClick={() => navigate(`/`)}>
                <RxCross1 style={{fontSize: '26px'}} />
              </span>
            </Col>
            <Col className='col-6' >
            </Col>
          </Row>

          <h3 className='my-4'> {language?.homePage?.OrderHistory?.[hrMobileLanguage]}</h3>

          <div className='container my-3'>
            {orderHistories?.length ? (
              <Row className="order-history-list">
                <table className="table">
                  <tbody>
                    {orderHistories?.map((v, i) => (
                      <tr key={i} style={{lineHeight: '3rem', cursor: 'pointer'}} onClick={() => {
                        setCurrentOrder(v);
                        setShowHistories(false);
                      }}>
                        <td className='text-nowrap text-truncate'>{
                          formatDateTime({
                            dateTime: v?.createdAt,
                            dateOnly: true,
                            convertToThai: hrMobileLanguage == 'th' ? true : false,
                          })
                        }</td>
                        <td className='text-nowrap text-truncate' style={{color: orderStatusMappings?.find((osm) => osm?.key == v?.orderStatus?.orderStatusId)?.color}}>
                          {orderStatusMappings?.find((osm) => osm?.key == v?.orderStatus?.orderStatusId)?.displayLabel?.[hrMobileLanguage]}
                        </td>
                        <td className='text-nowrap text-truncate' style={{fontWeight: 'bold'}}>{v?.total?.toLocaleString('en-US') || 0} ฿</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Row>
            ) : (
              <div style={{minHeight: '70vh'}}>
                <Lottie animationData={dataNotFound} speed={10} loop={true} style={{width: '40%', margin: '5rem auto 0 auto'}} />
                <p className='text-center font-weight-bold'>{language?.noRecords?.[hrMobileLanguage]}</p>
              </div>
            )}

            {!(promiseInProgress || isFullLoading) && (
              <div className="px-5 py-2 order-history-btn animated-hover-btn bg-dark mt-4 float-box-shadow">
                <h3 className='text-white my-2 text-center' onClick={() => navigate(`/`)}>{language?.homePage?.btnOK?.[hrMobileLanguage]}</h3>
              </div>
            )}
          </div>
        </div>
        ) : (
          <OrderHistoryDetail orderHistory={currentOrder} setShowHistories={setShowHistories}/>
        )}
    </>
  );
};

export default OrderHistory;
