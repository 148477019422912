export const syncLogTypes = {
  product: 'products',
  stock: 'stock',
  price: 'price',
};

export const syncLogTypeMappings = [
  {
    key: syncLogTypes.product,
    displayLabel: 'Product',
  },
  {
    key: syncLogTypes.stock,
    displayLabel: 'Stock',
  },
  {
    key: syncLogTypes.price,
    displayLabel: 'Price',
  },
];

export const syncLogStatuses = {
  fail: 0,
  success: 1,
};

export const syncLogStatusMappings = [
  {key: syncLogStatuses.fail, displayLabel: 'Fail', color: 'red'},
  {key: syncLogStatuses.success, displayLabel: 'Success', color: 'green'},
];
