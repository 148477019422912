import {Col, Row} from 'react-bootstrap';
import {MdArrowBackIosNew} from 'react-icons/md';
import {useEffect, useState} from 'react';
import {orderStatus, orderStatusMappings, paymentMethod} from '../../types/order';
import {GoSync} from 'react-icons/go';

import '../../styles/back-office/sale-order-detail.scss';
import {saleOrderDropdownLabelMappings} from '../../types/back-office/order';
import {trackPromise} from 'react-promise-tracker';
import {useDispatch, useSelector} from 'react-redux';
import {setFullLoading} from '../../store/appSlice';
import {apiGet, apiPost} from '../../apis';
import {useMsal} from '@azure/msal-react';
import Swal from 'sweetalert2';
import _ from 'lodash';
import {ProductLazyLoadImg} from '../user/ProductImg';
import {EnlargeProductImg} from '../EnlargeProductImg';
import {formatDateTime} from '../../helpers/datetime';

export const SaleOrderDetail = (props) =>{
  const dispatch = useDispatch();
  const {instance, accounts} = useMsal();
  const [inputSelectedStatus, setInputSelectedStatus] = useState();
  const [inputNote, setInputNote] = useState();
  const authUser = useSelector((state) => state.auth.auth);

  useEffect(() => {
    if (!props?.orderDetail) return;

    setInputSelectedStatus(saleOrderDropdownLabelMappings?.find((sodm) =>
      sodm?.currentStatus == props?.orderDetail?.orderStatusId)?.allowedStatuses[0]?.key,
    );
  }, [props?.orderDetail]);

  const update = async ()=>{
    if (!inputSelectedStatus || !props?.orderDetail) return;

    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            await apiPost({
              uri: `Order/UpdateStatus`,
              payload: {
                'orderNumber': props?.orderDetail?.orderNumber,
                'orderStatusId': inputSelectedStatus,
                'note': inputNote || '',
              },
              msal: {
                instance: instance,
                accounts: accounts,
              },
            });

            setInputNote('');
            props?.callDetailApi({orderNumber: props?.orderDetail?.orderNumber});

            Swal.fire({
              title: 'Updated!',
              // text: 'Session expired!',
              icon: 'success',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              allowEscapeKey: false,
            });

            dispatch(setFullLoading(false));
          } catch (error) {
            dispatch(setFullLoading(true));
            console.error(error);
          }
        })(),
    );
  };

  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    if (!props?.orderDetail?.employeeId) return;

    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            const getProfile = await apiGet({
              uri: `Employee/GetUserBasicInfo?emailOrId=${props?.orderDetail?.employeeId}`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
              callHrMobile: true,
              useHrMobileSecondaryApi: true,
              displayCustomError: true,
            });

            setUserProfile(getProfile);

            dispatch(setFullLoading(false));
          } catch (error) {
            console.error(error);
          }
        })(),
    );
  }, [props?.orderDetail?.employeeId, dispatch, instance, accounts]);

  const [showModal, setShowModal] = useState(false);
  const [enlargeProduct, setEnlargeProduct] = useState();

  const [canUpdateStatus, setCanUpdateStatus] = useState(false);

  useEffect(() => {
    if (!authUser || !props?.orderDetail) return;
    setCanUpdateStatus(false);

    if (authUser?.isAdmin) {
      if (!(props?.orderDetail?.orderStatusId == orderStatus.closed ||
      props?.orderDetail?.orderStatusId == orderStatus.cancelled ||
      props?.orderDetail?.orderStatusId == orderStatus.timeout ||
      props?.orderDetail?.orderStatusId == orderStatus.paymentFailed)) {
        setCanUpdateStatus(true);
      }
    }

    if (authUser?.isPopRole) {
      if (props?.orderDetail?.orderStatusId == orderStatus.pending) {
        setCanUpdateStatus(true);
      }
    }
  }, [authUser, props?.orderDetail]);

  return (
    <>
      <EnlargeProductImg showModal={showModal} setShowModal={setShowModal} product={enlargeProduct} setEnlargeProduct={setEnlargeProduct} />

      <div className='sale-order-detail' >
        <div className='mb-4 d-flex align-items-center'>
          <div onClick={() => props?.setOrderDetail(null)} className='pointer'>
            <MdArrowBackIosNew size={'20px'} />
            <span className='ms-2'>Sales Order</span>
          </div>
        </div>

        <Row className=''>
          <Col className='col-8 custom-card p-4 me-3 left-panel'>
            <Row>
              <Col className='col-5'>
                <p className='font-weight-bold'>Invoice to:</p>
                <hr />
                <p>Employee id: <span className='float-right'>{props?.orderDetail?.employeeId}</span></p>
                <p>Name: <span className='float-right'>{userProfile?.employeeNameEn}</span></p>
                <p>Email: <span className='float-right'>{userProfile?.email}</span></p>
                <p>Phone: <span className='float-right'>{userProfile?.mobileNo}</span></p>
              </Col>
              <Col className='col'>
                <div className='float-right'>
                  <p className='font-weight-bold'>Payment details:</p>
                  <hr />
                  <p>Order number: <span className='float-right ms-2'>{props?.orderDetail?.orderNumber}</span></p>
                  <p>
                Order status:
                    <span className='ms-2 float-right' style={{
                      color: orderStatusMappings?.find((osm) => osm.key == props?.orderDetail?.orderStatusId)?.color,
                    }}>
                      {orderStatusMappings?.find((osm) => osm.key == props?.orderDetail?.orderStatusId)?.displayLabel?.en}
                    </span>
                  </p>
                  <p>Date issued: <span className='float-right ms-2'>{formatDateTime({
                    dateTime: props?.orderDetail?.createdAt,
                  })}</span></p>
                </div>
              </Col>
            </Row>

            <table className="table table-borderless table-hover table-striped mt-5">
              <thead>
                <tr className='table-active text-uppercase '>
                  <th scope="col" className='font-normal'>image</th>
                  <th scope="col" className='font-normal'>item</th>
                  <th scope="col" className='font-normal'>size</th>
                  <th scope="col" className='font-normal'>quantity</th>
                  <th scope="col" className='font-normal'>price</th>
                </tr>
              </thead>
              <tbody>
                {props?.orderDetail?.orderDetails?.map((v, i) => (
                  <tr key={i} className='pointer' onClick={() => {
                    setEnlargeProduct(v);
                    setShowModal(true);
                  }}>
                    <td className='w-10'><ProductLazyLoadImg className={'w-100'} imgId={v?.image} /></td>
                    <td>{v?.sku}</td>
                    <td>{v?.size || 'N/A'}</td>
                    <td>{v?.qty || 0}</td>
                    <td>{v?.price?.toLocaleString('en-US') || 0} ฿</td>
                  </tr>
                ))}
              </tbody>
            </table>

            <Row className=''>
              <Col xs={8}></Col>
              <Col xs={4} >
                <div className='fw-bold'>
                  <p>Payment: <span className='float-right'>{props?.orderDetail?.payment?.paymentType == paymentMethod.promptPay ? 'PromptPay' : 'Banking'}</span></p>
                  <p>Total: <span className='float-right'>{props?.orderDetail?.total?.toLocaleString('en-US') || 0} ฿</span></p>
                </div>
              </Col>
            </Row>
          </Col>
          <Col className='col right-panel'>
            <Row>

              {canUpdateStatus && (
                <Col xs={12} className='custom-card p-4 mb-4'>
                  <h5>Next Action</h5>

                  <div className="form-group mt-4">
                    <label htmlFor="status">Status: </label>
                    <select className="form-select admin-form-select" id="status"
                      onChange={(e) => setInputSelectedStatus(e.target.value)}
                    >
                      {saleOrderDropdownLabelMappings?.find((sodm) => sodm?.currentStatus == parseInt(props?.orderDetail?.orderStatusId))?.allowedStatuses?.map((osm, i) => (
                        <option value={osm?.key} key={i}>{osm?.displayLabel?.en}</option>
                      ))}
                    </select>
                  </div>

                  <div className="form-group mt-4">
                    <label htmlFor="exampleFormControlTextarea1">Note: </label>
                    <textarea className="form-control admin-form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => setInputNote(e.target.value)} value={inputNote}></textarea>
                  </div>

                  {inputNote && (
                    <div className="form-group mt-4">
                      <div className='admin-btn admin-btn-primary w-100 d-flex align-items-center justify-content-center' onClick={update}>
                        <GoSync size={'18px'} className='me-2' />
                        <span>Update</span>
                      </div>
                    </div>
                  )}
                </Col>
              )}

              <Col xs={12} className='custom-card p-4 '>
                <h5>Status History</h5>

                <ul className="timelines">
                  {_.orderBy(props?.orderDetail?.orderHistories, ['createdAt'], ['desc'])?.map((v, i) => (
                    <li key={i}>
                      <small style={{
                        color: orderStatusMappings?.find((osm) => osm.key == v?.orderStatusId)?.color,
                      }}>{orderStatusMappings?.find((osm) => osm.key == v?.orderStatusId)?.displayLabel?.en || ''}</small>
                      <div className="time">{formatDateTime({
                        dateTime: v?.createdAt,
                      })}</div>
                      <div className="note">{v?.note}</div>
                    </li>
                  ))}
                </ul>
              </Col>
            </Row>

          </Col>
        </Row>
      </div>
    </>
  );
};
