import {createSlice} from '@reduxjs/toolkit';
import {getCurrentLanguage} from '../helpers/language-switcher';

const initialState = {
  isFullLoading: true,
  slsHrMobileLanguage: getCurrentLanguage(),
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setFullLoading: (state, action) => {
      return {
        ...state,
        isFullLoading: action.payload,
      };
    },
    setAppLanguage: (state, action) => {
      return {
        ...state,
        slsHrMobileLanguage: action.payload,
      };
    },
  },
});

export const {setFullLoading, setAppLanguage} = appSlice.actions;
export default appSlice.reducer;
