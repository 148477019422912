import Axios from 'axios';
import {displayApiError, logout} from '../helpers/logout';
import {callMsal} from './msal';

let apiHrMobileEndpoint = process.env.REACT_APP_HR_MOBILE_API_END_POINT;
const hrMobileSecondaryApi = process.env.REACT_APP_HR_MOBILE_SECONDARY_API_END_POINT;
const apiEndpoint = process.env.REACT_APP_API_END_POINT + '/api/v1';
const getCallingMaps = new Map();
const postCallingMaps = new Map();

export const apiGet = async ({
  uri = '',
  msal = null,
  callHrMobile = false,
  useHrMobileSecondaryApi = false,
  displayCustomError = false,
} = {}) => {
  try {
    if (useHrMobileSecondaryApi) {
      apiHrMobileEndpoint = hrMobileSecondaryApi;
    }

    const url = `${callHrMobile ? apiHrMobileEndpoint : apiEndpoint}/${uri}`;
    let isThisAPICalling = getCallingMaps.get(url);
    const accessToken = await getAccessToken({msal});

    if (!isThisAPICalling) {
      isThisAPICalling = Axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      getCallingMaps.set(url, isThisAPICalling);
    }

    return isThisAPICalling.then((res) => {
      getCallingMaps.set(url, null);

      return res?.data || undefined;
    }).catch((err) => {
      console.log(`apiPost error`, err);

      if (err?.response?.status == 401) {
        return logout({msal});
      }

      if (displayCustomError) {
        throw err;
      }

      return displayApiError();
    });
  } catch (error) {}
};

export const apiPost = async ({
  uri = '',
  payload = {},
  msal = null,
  callHrMobile = false,
  useHrMobileSecondaryApi = false,
  displayCustomError = false} = {},
) => {
  try {
    if (useHrMobileSecondaryApi) {
      apiHrMobileEndpoint = hrMobileSecondaryApi;
    }

    const url = `${callHrMobile ? apiHrMobileEndpoint : apiEndpoint}/${uri}`;
    let isThisAPICalling = postCallingMaps.get(url);
    const accessToken = await getAccessToken({msal});

    if (!isThisAPICalling) {
      isThisAPICalling = Axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      postCallingMaps.set(url, isThisAPICalling);
    }

    return isThisAPICalling.then((res) => {
      postCallingMaps.set(url, null);

      return res?.data || undefined;
    }).catch((err) => {
      console.log(`apiPost error`, err);

      if (err?.response?.status == 401) {
        return logout({msal});
      }

      if (displayCustomError) {
        throw err;
      }

      return displayApiError();
    });
  } catch (error) {}
};

export const apiDelete = async ({
  uri = '',
  payload = {},
  msal = null,
} = {}) => {
  try {
    const url = `${apiEndpoint}/${uri}`;
    let isThisAPICalling = postCallingMaps.get(url);
    const accessToken = await getAccessToken({msal});

    if (!isThisAPICalling) {
      isThisAPICalling = Axios.delete(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: payload,
      });

      postCallingMaps.set(url, isThisAPICalling);
    }

    return isThisAPICalling.then((res) => {
      postCallingMaps.set(url, null);

      return res?.data || undefined;
    }).catch((err) => {
      console.log(`apiDelete error`, err);

      if (err?.response?.status == 401) {
        return logout({msal});
      }

      return displayApiError();
    });
  } catch (error) {}
};

const getAccessToken = async ({msal = null} = {})=>{
  try {
    let accessToken = '';

    if (msal) {
      const msalCall = await callMsal({msal});
      accessToken = msalCall?.accessToken || '';
    } else {
      // don't delete this.. will use in cypress
      if (process.env.REACT_APP_ENV !== 'production') {
        const queryParams = new URLSearchParams(window.location.search);

        if (queryParams.get('hrMobileAccessToken')) {
          localStorage.setItem('accessToken', queryParams.get('hrMobileAccessToken'));
        }

        accessToken = queryParams.get('hrMobileAccessToken') ||
          localStorage.getItem('accessToken') ||
          localStorage.getItem('AccessTokens');
      }
    }

    return accessToken;
  } catch (error) {
    console.error(`getAccessToken error`, error);
    return false;
  }
};
