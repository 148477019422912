
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import {useEffect, useState} from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
);

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Order Summary By Site',
    },
  },
  maintainAspectRatio: false,
};

export const DashboardLineGraph = (props) =>{
  const [data, setData] = useState();

  useEffect(() => {
    if (!props?.graphSummaries) return;

    setData({
      labels: props?.graphSummaries[0]?.info?.map((v) => v?.label),
      datasets: props?.graphSummaries?.map((v, i) => {
        return {
          label: v?.site,
          data: v?.info?.map((infoV) => infoV?.value),
          borderColor: getColors({index: i})[0],
          backgroundColor: getColors({index: i})[1],
        };
      }),
    });
  }, [props?.graphSummaries]);

  const getColors = ({index})=>{
    let colors = ['rgb(255, 99, 132)', 'rgba(255, 99, 132, 0.5)'];

    switch (index) {
      case 0:
        colors = ['rgb(255, 99, 132)', 'rgba(255, 99, 132, 0.5)'];
        break;
      case 1:
        colors = ['rgb(53, 162, 235)', 'rgba(53, 162, 235, 0.5)'];
        break;
      case 2:
        colors = ['rgb(51, 255, 189)', 'rgba(51, 255, 189, 0.5)'];
        break;
    }

    return colors;
  };

  if (!data) {
    return <></>;
  }

  return <Line options={options} width={'100%'} data={data} />;
};
