import {Col, Row} from 'react-bootstrap';
import {RxCross1} from 'react-icons/rx';
import {language} from '../../language';
import '../../styles/user/main.scss';
import '../../styles/user/cart.scss';
import {useEffect, useState} from 'react';
import {transformDivBottomToTop} from '../../helpers/animations/common';
import _ from 'lodash';
import {setFullLoading} from '../../store/appSlice';
import {useDispatch} from 'react-redux';
import {paymentMethod} from '../../types/order';
import {apiGet, apiPost} from '../../apis';
import {LuTrash} from 'react-icons/lu';
import Swal from 'sweetalert2';
import sls from 'react-secure-storage';
import {ProductLazyLoadImg} from '../../components/user/ProductImg';
import {confirmDeleteItemSwal, invalidStockInCart, paymentOptionFail} from '../../helpers/swal/cart';
import {useSelector} from 'react-redux';
import TermsAndConditionsModal from '../../components/user/TermsAndConditionsModal';
import {useMsal} from '@azure/msal-react';
import {useNavigate} from 'react-router-dom';
import {MdArrowBackIosNew} from 'react-icons/md';
import {PaymentOptions} from '../../components/user/PaymentOptions';

const Cart = (props) => {
  const {instance, accounts} = useMsal();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isConfirmOrder, setIsConfirmOrder] = useState(false);
  const slsHrMobileData = sls.getItem('slsHrMobileData');
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);
  const [showBankingOptions, setShowBankingOptions] = useState(false);
  const [bankOptionName, setBankOptionName] = useState('');
  const [isAgreed, setIsAgreed] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowBankingOptions(false);
    setBankOptionName('');
    setIsAgreed(false);
    setShowModal(false);
  }, [isConfirmOrder]);

  useEffect(() => {
    window.scrollTo(0, 0);
    transformDivBottomToTop();
  }, []);

  useEffect(() => {
    if (!props?.cartItems?.length) {
      props?.setShowCartPage(false);
    }
  }, [props]);

  const getProductsBySkus = async (skus) =>{
    try {
      props?.setShowCartBtnLoading(true);

      const res = await apiPost({
        uri: 'Products/realdata',
        msal: {
          instance: instance,
          accounts: accounts,
        },
        payload: skus || [],
      });

      return res;
    } catch (error) {
      console.error(error);
    }
  };

  const updateCartWithNewItems = async ({products = [], cartItems = []} = {}) =>{
    try {
      props?.setShowCartBtnLoading(true);

      const newCartItems = cartItems?.map((ct) => {
        const currentProduct = products?.find((p) => p?.sku == ct?.sku);
        let newQty = ct?.qty;

        // update stock if stock is not enough
        if (ct?.qty > currentProduct?.stock) {
          newQty = currentProduct?.stock;
        }

        // remove item from cart if item is no more available
        if (currentProduct?.status == 0 || newQty <= 0) {
          return false;
        }

        return {
          sku: ct?.sku,
          qty: newQty || 0,
        };
      })?.filter((v) => v);

      await apiPost({
        uri: 'Cart/update',
        msal: {
          instance: instance,
          accounts: accounts,
        },
        payload: {
          employeeId: slsHrMobileData?.authUser?.employeeId || '',
          cart1: newCartItems || [],
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const callOrderApi = async () =>{
    (async () => {
      try {
        props?.setShowCartBtnLoading(true);

        const [cartItems, cartLimitation] = await props?.callCartApi();
        const cartSkus = cartItems?.map((ct) => ct?.sku);
        const products = await getProductsBySkus(cartSkus);
        const hasMissingItems = cartItems?.some((ct) => {
          const p = products?.find((p) => ct?.sku == p?.sku);
          return (p?.status == 0) ? true : false;
        });
        const hasInvalidStocks = cartItems?.some((ct) => {
          const p = products?.find((p) => ct?.sku == p?.sku);
          return ct?.qty > p?.stock ? true : false;
        });
        const invalidCart = hasMissingItems || hasInvalidStocks;

        console.log('hasMissingItems', hasMissingItems);
        console.log('hasInvalidStocks', hasInvalidStocks);

        if (invalidCart) {
          Swal.fire(invalidStockInCart).then(async () =>{
            await updateCartWithNewItems({products: products, cartItems: cartItems});

            setIsConfirmOrder(false);
            await props?.callCartApi();
          });

          return;
        }

        // check order limit
        if (cartLimitation?.limitStatus) {
          Swal.fire( {
            title: language?.warning?.[hrMobileLanguage],
            text: language?.homePage?.limitMessageFrist?.[hrMobileLanguage] + cartLimitation?.itemLimitPerMonth + language?.homePage?.limitMessageLast?.[hrMobileLanguage],
            icon: 'warning',
            confirmButtonText: language?.homePage?.btnOK?.[hrMobileLanguage],
            cancelButtonText: language?.homePage?.btnCancel?.[hrMobileLanguage],
            confirmButtonColor: 'black',
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          return;
        }

        // order process
        if (isConfirmOrder) {
          const orderRes = await apiPost({
            uri: 'Order/create',
            msal: {
              instance: instance,
              accounts: accounts,
            },
            payload: {
              'employeeId': slsHrMobileData?.authUser?.employeeId || '',
              'site': slsHrMobileData?.authUser?.site || '',
              'paymentMethod': showBankingOptions ? paymentMethod?.banking : paymentMethod?.promptPay,
              'bankName': bankOptionName,
            },
          });

          if (showBankingOptions) {
            if (orderRes?.chargesPayment?.authorizeUri) {
              window.location.href = orderRes?.chargesPayment?.authorizeUri;
            }

            setTimeout(() => {
              Swal.fire(paymentOptionFail).then((res) =>{
                if (res?.isConfirmed) {
                  navigate(`/order-histories?orderNumber=${orderRes?.orderNumber}`);
                  return;
                }

                window.location.href = process.env.PUBLIC_URL;
              });
            }, 1000 * 5);

            return;
          } else {
            navigate(`/order/qr-payment?qrUrl=${orderRes?.chargesPayment?.qrPromtpay}&startedDate=${orderRes?.chargesPayment?.created_at}&orderNumber=${orderRes?.orderNumber}`);
            return;
          }
        }

        props?.setShowCartBtnLoading(false);
      } catch (error) {
        dispatch(setFullLoading(true));
        console.error(error);
      }
    })();
  };

  const removeItemFromCart = async ({sku = null, stock = 0} = {})=>{
    Swal.fire(confirmDeleteItemSwal).then(async (result) =>{
      if (result.isConfirmed) {
        await props?.callAddToCartApi({sku, stock, isRemoveItem: true}, {decrease: true});
      }
    });
  };

  const getUserBasicInfo = async () =>{
    try {
      props?.setShowCartBtnLoading(true);

      const fullEmpInfo = await apiGet({
        uri: `Employee/GetUserBasicInfo?emailOrId=${slsHrMobileData?.authUser?.employeeId}`,
        msal: {
          instance: instance,
          accounts: accounts,
        },
        callHrMobile: true,
        useHrMobileSecondaryApi: true,
      });

      props?.setShowCartBtnLoading(false);
      return fullEmpInfo;
    } catch (error) {
      dispatch(setFullLoading(true));
      console.error(error);
    }
  };

  // validate can click order button
  const [canClickOrderBtn, setCanClickOrderBtn] = useState(false);
  useEffect(() => {
    if (!isConfirmOrder) return;

    setCanClickOrderBtn(false);

    if (isAgreed) {
      setCanClickOrderBtn(true);
    }

    if (showBankingOptions) {
      setCanClickOrderBtn(false);

      if (bankOptionName && isAgreed) {
        setCanClickOrderBtn(true);
      }
    }
  }, [isConfirmOrder, isAgreed, showBankingOptions, bankOptionName]);

  return (
    <>
      <TermsAndConditionsModal showModal={showModal} setShowModal={setShowModal}/>

      <div className="cart">
        <Row>
          <Col className='col-6'>
            <span style={{cursor: 'pointer'}} onClick={() => {
              if (isConfirmOrder) {
                setIsConfirmOrder(false);
                return;
              }

              props?.setShowCartPage(false);
            }}>
              {isConfirmOrder ? (
                <MdArrowBackIosNew style={{fontSize: '26px'}}/>
              ) : (
                <RxCross1 style={{fontSize: '26px'}} />
              )}
            </span>
          </Col>
          <Col className='col-6' >
          </Col>
        </Row>

        <h3 className='my-4'>{language?.homePage?.Cart?.cart?.[hrMobileLanguage]}</h3>

        <div className='transform-bottom-to-top_'>
          <div className='container my-3'>
            <Row className="cart-list">
              {props?.cartItems?.map((v, i) => (
                <Col className='col-12 mb-4' key={i}>
                  <div className="cart-item">
                    <Row>
                      <Col className='col-4'>
                        <ProductLazyLoadImg className="item-img" imgId={v?.image}/>
                      </Col>

                      <Col className='col-8'>
                        <p className='d-inline-block text-truncate mb-1 text-dark fw-bold' style={{maxWidth: '100%'}}>{v?.productName}</p>

                        <Row className='d-flex align-items-center justify-content-center mb-2'>
                          <Col className='col-8 pe-0'>
                            <div>
                              {!isConfirmOrder && (
                                <svg data-cy={`decrease-qty-btn-${i}`} style={{cursor: 'pointer'}} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={async () =>{
                                  const {sku, qty} = v;

                                  if (qty == 1) {
                                    Swal.fire(confirmDeleteItemSwal).then(async (result) =>{
                                      if (result.isConfirmed) {
                                        await props?.callAddToCartApi({sku, stock: v?.currentStock}, {decrease: true});
                                      }
                                    });

                                    return;
                                  }

                                  await props?.callAddToCartApi({sku, stock: v?.currentStock}, {decrease: true});
                                }}>
                                  <rect width="40" height="40" rx="20" fill="#F9F8F6" />
                                  <rect x="14" y="19" width="12" height="2" rx="1" fill="black" />
                                </svg>
                              )}

                              <span className='mx-2 text-secondary'>
                                {isConfirmOrder && (
                                  <span style={{fontSize: '14px'}}>{language?.homePage?.Cart?.Quantity?.[hrMobileLanguage]}: </span>
                                )}

                                <span className='text-dark fw-bold'>
                                  {v?.qty}
                                </span>
                              </span>

                              {!isConfirmOrder && (
                                <svg data-cy={`increase-qty-btn-${i}`} style={{cursor: 'pointer'}} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={async () =>{
                                  const {sku} = v;

                                  await props?.callAddToCartApi({sku, stock: v?.currentStock}, {decrease: false});
                                }}>
                                  <rect width="40" height="40" rx="20" fill="#F9F8F6" />
                                  <rect x="14" y="19" width="12" height="2" rx="1" fill="black" />
                                  <path fillRule="evenodd" clipRule="evenodd" d="M21 14.75V25.25C21 25.6642 20.5523 26 20 26C19.4477 26 19 25.6642 19 25.25V14.75C19 14.3358 19.4477 14 20 14C20.5523 14 21 14.3358 21 14.75Z" fill="black" />
                                </svg>
                              )}
                            </div>
                          </Col>
                          <Col className='col-4 ps-0'>
                            <span className='text-dark fw-bold'>{v?.price?.toLocaleString('en-US')} ฿</span>
                          </Col>
                        </Row>

                        {v?.size && (
                          <>
                            <span className='mx-2 mt-4 text-secondary' style={{fontSize: '14px'}}>
                              <span >{language?.homePage?.Filter?.Size?.[hrMobileLanguage]}: </span>

                              <span className='text-dark fw-bold'>
                                {v?.size?.replace(',', '.')}
                              </span>
                            </span>
                            <br />
                          </>
                        )}

                        {v?.currentStock == 0 && (
                          <span className='mx-2 mt-4 text-danger' style={{fontSize: '14px'}}>{language?.homePage?.Cart?.OutOfStock?.[hrMobileLanguage]}</span>
                        )}

                        {!isConfirmOrder && (
                          <p data-cy="remove-item" className='mt-2 mx-2' onClick={async () =>{
                            await removeItemFromCart({sku: v?.sku, stock: v?.currentStock});
                          }} >
                            <LuTrash color='#F97C89'/>
                            <small className='ms-1 delete-cart'>{language?.homePage?.Cart?.Removeitem?.[hrMobileLanguage]}</small>
                          </p>
                        )}

                      </Col>
                    </Row>
                  </div>
                </Col>
              ))}

            </Row>

            <ul className="list-group list-group-flush mt-2 ">
              <li className="list-group-item text-dark fw-bold" style={{fontSize: '20px'}}>{language?.total?.[hrMobileLanguage]}: <span className='text-dark' style={{float: 'right'}}>{_.sumBy(props?.cartItems, 'subTotalPerItem')?.toLocaleString('en-US') || 0} ฿</span></li>
            </ul>

            {isConfirmOrder && (
              <>
                <div className='px-2 mb-4 mt-3'>
                  <PaymentOptions
                    showBankingOptions={showBankingOptions}
                    setShowBankingOptions={setShowBankingOptions}
                    setBankOptionName={setBankOptionName}
                    bankOptionName={bankOptionName}
                  />
                </div>

                <small className=''>
                  <div className="form-check mb-5">
                    <input data-cy="terms-and-conditions-checkbox" className="form-check-input" type="checkbox" value={isAgreed} checked={isAgreed} onChange={() => setIsAgreed(!isAgreed)} />
                    <label className="form-check-label">
                      {language?.termAndCondition?.text?.[hrMobileLanguage]}<span className='text-primary pointer' onClick={() => setShowModal(true)}> {language?.termAndCondition?.link?.[hrMobileLanguage]}</span>
                    </label>
                  </div>
                </small>
              </>
            )}

            {props?.showCartBtnLoading ? (
              <div className={`inline-btn-loader px-5 py-2 checkout-btn animated-hover-btn mt-4 float-box-shadow ${isConfirmOrder ? 'bg-red' : 'bg-blue'} pe-none`}>
                <Row>
                  <Col className='col-12 p-0'>
                    <span className="loader"></span>
                  </Col>
                </Row>
              </div>
            ) : (
              <>
                <div data-cy="order-btn" className={`px-5 py-2 checkout-btn animated-hover-btn mt-4 float-box-shadow ${isConfirmOrder ? canClickOrderBtn ? 'bg-red' : 'bg-gray-disabled' : 'bg-blue'}`} onClick={async () => {
                  try {
                    if (isConfirmOrder) {
                      if (canClickOrderBtn) {
                        await callOrderApi();
                        return;
                      } else {
                        window.scrollTo(0, document.body.scrollHeight);
                        return;
                      }
                    }

                    // check probation
                    const cartUserInfo = await getUserBasicInfo();
                    if (cartUserInfo?.probrationPassed == false) {
                      Swal.fire( {
                        title: language?.warning?.[hrMobileLanguage],
                        text: language?.orderProbationWaring?.[hrMobileLanguage],
                        icon: 'warning',
                        confirmButtonText: language?.homePage?.btnOK?.[hrMobileLanguage],
                        confirmButtonColor: 'black',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                      });

                      return;
                    }

                    // check order limit
                    const [, cartLimitation] = await props?.callCartApi();
                    if (cartLimitation?.limitStatus) {
                      Swal.fire( {
                        title: language?.warning?.[hrMobileLanguage],
                        text: language?.homePage?.limitMessageFrist?.[hrMobileLanguage] + cartLimitation?.itemLimitPerMonth + language?.homePage?.limitMessageLast?.[hrMobileLanguage],
                        icon: 'warning',
                        confirmButtonText: language?.homePage?.btnOK?.[hrMobileLanguage],
                        cancelButtonText: language?.homePage?.btnCancel?.[hrMobileLanguage],
                        confirmButtonColor: 'black',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                      });

                      return;
                    }

                    window.scrollTo(0, 0);
                    setIsConfirmOrder(true);
                  } catch (error) {
                    console.error(error);
                  }
                }}>
                  <Row>
                    <Col className='col-12 p-0'>
                      <h3 className='text-white text-center text-overflow my-2'>{isConfirmOrder ? language?.homePage?.Cart?.ConfirmPayment?.[hrMobileLanguage] : language?.homePage?.Cart?.CheckOut?.[hrMobileLanguage]}</h3>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Cart;
