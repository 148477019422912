import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Provider} from 'react-redux';
import store from './store/store';
import {MsalProvider} from '@azure/msal-react';
import {PublicClientApplication} from '@azure/msal-browser';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: `${process.env.REACT_APP_CLIENTID}`,
    authority: `${process.env.REACT_APP_AUTHORITY}`,
    redirectUri: `${process.env.REACT_APP_END_POINT}`,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.REACT_APP_ENV == 'production') {
  console.log = function() {};
}

root.render(
    <React.StrictMode>
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
