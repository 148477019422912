export const transformDivBottomToTop = () => {
  const transformDivs = document.getElementsByClassName('transform-bottom-to-top');

  setTimeout(() => {
    for (const transformDiv of transformDivs) {
      transformDiv.classList.add('visible');
    }
  }, 100);
};

export const transformDivRightToLeft = () => {
  const transformDivs = document.getElementsByClassName('transform-right-to-left');

  setTimeout(() => {
    for (const transformDiv of transformDivs) {
      transformDiv.classList.add('visible');
    }
  }, 100);
};
