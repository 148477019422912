/* eslint-disable no-unreachable */
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {usePromiseTracker} from 'react-promise-tracker';
import {UserRoutes} from './routes/UserRoutes';
import {BackOfficeRoutes} from './routes/BackOfficeRoutes';
import sls from 'react-secure-storage';
import packageJson from '../package.json';
import {clearAllStorages} from './helpers/logout';
import {AuthenticatedTemplate, UnauthenticatedTemplate} from '@azure/msal-react';
import {AzureLogin} from './components/AzureLogin';
import {FullLoading} from './components/FullLoading';

const App = () => {
  // prevent browser back and front btn
  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function(event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, []);

  // START: redirect to Hr Mobile if use access to TPPP directly
  // if (window.location.pathname == '/' || window.location.pathname == '') {
  //   window.location.href = process.env.REACT_APP_HR_MOBILE_URL;
  // }
  // END: redirect to Hr Mobile if use access to TPPP directly

  const isFullLoading = useSelector((state) => state.app.isFullLoading);
  const {promiseInProgress} = usePromiseTracker();

  // START: clear cache
  useEffect(() => {
    if (promiseInProgress) return;

    const version = sls.getItem('version');

    if (version && version != packageJson?.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }

      clearAllStorages();

      window.location.reload(true);
    }

    sls.setItem('version', packageJson?.version);
  }, [promiseInProgress]);
  // END: clear cache

  const [layoutName, setLayoutName] = useState();

  useEffect(() => {
    setLayoutName('user');
    if (window.location.pathname.includes('back-office')) {
      setLayoutName('back-office');
    }

    if (promiseInProgress || isFullLoading) {
      document.body.classList.add('disable-page-scroll');
    } else {
      document.body.classList.remove('disable-page-scroll');
    }
  }, [promiseInProgress, isFullLoading]);

  return (
    <>
      <AuthenticatedTemplate>
        {(promiseInProgress || isFullLoading) && (
          <FullLoading />
        )}

        {layoutName == 'back-office' && (
          <BackOfficeRoutes />
        )}

        {layoutName == 'user' && (
          <UserRoutes />
        )}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>{<AzureLogin />}</UnauthenticatedTemplate>

    </>
  );
};

export default App;
