import {useEffect, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import {MdArrowBackIosNew} from 'react-icons/md';
import {useLocation} from 'react-router-dom';
import ProductDetailModal from './ProductDetailModal';
import {language} from '../../language';
import {apiGet, apiPost} from '../../apis';
import {useDispatch, useSelector} from 'react-redux';
import {setFullLoading} from '../../store/appSlice';
import {trackPromise, usePromiseTracker} from 'react-promise-tracker';
import ProductItems from '../../components/user/ProductItems';
import _ from 'lodash';
import NavFilter from '../../components/user/NavFilter';
import PriceRangeFilter from '../../components/user/PriceRangeFilter';
import Cart from './Cart';
import Swal from 'sweetalert2';
import {invalidStockInCart, outOfStockSwal} from '../../helpers/swal/cart';
import sls from 'react-secure-storage';
import {clearTpppStorages} from '../../helpers/logout';
import {useMsal} from '@azure/msal-react';
import Skeleton from '@mui/material/Skeleton';
import {CartBtn} from '../../components/user/CartBtn';

import '../../styles/user/main.scss';
import '../../styles/user/home.scss';

const Home = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const {instance, accounts} = useMsal();
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);
  const {promiseInProgress: showProductSkeleton} = usePromiseTracker({area: 'product-skeleton'});
  const [showProductSkeletonState, setShowProductSkeletonState] = useState(showProductSkeleton);
  const [showCartPage, setShowCartPage] = useState(false);
  const [showCategorySkeleton, setShowCategorySkeleton] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(setFullLoading(false));
  }, [dispatch, location, showCartPage]);

  // check page is loaded or not
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  useEffect(() => {
    const onPageLoad = () => {
      setIsPageLoaded(true);
    };

    if (document.readyState === 'complete') {
      onPageLoad();
    } else {
      window.addEventListener('load', onPageLoad, false);
      return () => window.removeEventListener('load', onPageLoad);
    }
  }, []);

  const backToHrMobileApp = ()=>{
    clearTpppStorages();
    return window.location.href = process.env.REACT_APP_HR_MOBILE_URL + '/service';
  };

  const slsHrMobileData = sls.getItem('slsHrMobileData');
  const isFullLoading = useSelector((state) => state.app.isFullLoading);
  const {promiseInProgress} = usePromiseTracker();
  const limit = 12;
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isOnScrollLoading, setIsOnScrollLoading] = useState(false);
  const defaultQty = 1;
  const [showDetailModal, setShowDetailModal] = useState(false);

  const [isSearchInputVisible, setSearchInputVisible] = useState(false);
  const toggleSearchInput = () => {
    setSearchInputVisible(!isSearchInputVisible);
  };

  const [showCartBtnLoading, setShowCartBtnLoading] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event?.target) return;

      if (event?.target?.classList?.contains('ignore-click-for-filter-popup') ||
          event?.target?.classList?.contains('MuiSlider-rail') ||
          event?.target?.classList?.contains('MuiSlider-track') ||
          event?.target?.classList?.contains('MuiSlider-thumb')
      ) {
        return;
      }

      if (event?.target?.classList?.contains('click-for-filter-popup')) {
        document.getElementsByClassName('click-for-filter-popup')[0]?.classList.toggle('visible');
      } else {
        document.getElementsByClassName('click-for-filter-popup')[0]?.classList.remove('visible');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState('all');
  const [products, setProducts] = useState([]);
  const [currentModalProduct, setCurrentModalProduct] = useState();
  const [cartItems, setCartItems] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (!isPageLoaded) {
          setShowCategorySkeleton(true);
        }

        await callProductApi();
      } catch (error) {
        dispatch(setFullLoading(true));
        console.error(error);
      }
    })();
  }, [location, dispatch, isPageLoaded]);

  const callCartApi = async ({toShowProductSkeleton = false} = {})=>{
    return trackPromise(
        (async () => {
          try {
            setShowCartBtnLoading(true);

            const getCarts = await apiGet({
              uri: `Cart/carts?employeeId=${slsHrMobileData?.authUser?.employeeId || ''}`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
            });

            if (getCarts?.cart1) {
              let cartData = getCarts?.cart1?.map((v) => {
                return {
                  ...v,
                  subTotalPerItem: v?.qty * v?.price,
                };
              });
              cartData = cartData?.filter((ct) => ct?.qty > 0);
              cartData = _.orderBy(cartData, ['sku'], ['desc']);
              setCartItems(cartData || []);
            } else {
              setCartItems([]);
            }

            setShowCartBtnLoading(false);
            dispatch(setFullLoading(false));

            return [getCarts?.cart1 || [], getCarts?.limitation || null];
          } catch (error) {
            dispatch(setFullLoading(true));
            console.error(error);
          }
        })(), toShowProductSkeleton ? 'product-skeleton' : 'null',
    );
  };

  const [filterMasterData, setFilterMasterData] = useState();

  const callProductApi = async ({formScroll = false, toShowProductSkeleton = true} = {}) =>{
    if (!isPageLoaded || !page) return;

    return trackPromise(
        (async () => {
          try {
            formScroll = page > 1 ? true : false;

            if (!formScroll) {
            // START: clear cached image src
              setProducts([]);
              setTimeout(() => {
                setProducts(products);
              }, .5);
            // END: clear cached image src
            }

            let getCategories = categories;
            if (!getCategories?.length) {
              console.log('called');

              setShowCategorySkeleton(true);
              getCategories = await apiGet({
                uri: 'Category/Categories',
                msal: {
                  instance: instance,
                  accounts: accounts,
                },
              });
              setCategories(getCategories);
            }

            let filterCat = getCategories || [];

            if (activeCategory != 'all') {
              const currentFilterCat = getCategories?.find((c) => c == activeCategory);
              filterCat = [currentFilterCat];
            }

            const filter = {};
            filter.stock = false;

            if (range[0]) {
              filter.minprice = range[0];
            }
            if (range[1]) {
              filter.maxprice = range[1];
            }
            if (sizes?.length) {
              filter.size = sizes;
            }
            if (stockOnly) {
              filter.stock = true;
            }

            // console.log('page !!!!', page);

            const offset = formScroll ? ((page-1) * limit) : 0;
            console.log('offset', offset);
            const getProducts = await apiPost({
              uri: 'Products/products',
              msal: {
                instance: instance,
                accounts: accounts,
              },
              payload: {
                ...filter,
                'categories': filterCat,
                'keyword': searchKeyword || '',
                'offset': offset,
                'limit': limit,
              },
            });
            const [cartItemsRes] = await callCartApi({toShowProductSkeleton: toShowProductSkeleton});
            const newProducts = await mapProductStockWithCartStock({productsParam: getProducts?.products, cartItemsParam: cartItemsRes});

            if (formScroll) {
              setProducts((prevItems) => {
                const merged = [...prevItems, ...newProducts];
                return _.uniqBy(merged, 'sku');
              });
            } else {
              setProducts(newProducts);
            }

            setHasMore(getProducts?.products.length > 0);
            setFilterMasterData({
              sizes: getProducts?.sizes || [],
              priceRange: getProducts?.priceRange || {},
            });

            setShowCategorySkeleton(false);
            setShowProductSkeletonState(false);
            setIsOnScrollLoading(false);
            dispatch(setFullLoading(false));
          } catch (error) {
            dispatch(setFullLoading(true));
            console.error(error);
          }
        })(), toShowProductSkeleton ? 'product-skeleton' : 'null',
    );
  };

  useEffect(() => {
    const handleScroll = async () => {
      if (showCartPage || isOnScrollLoading) return;

      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      // console.log('scrollTop', scrollTop);
      // console.log('clientHeight', clientHeight);
      // console.log('scrollHeight', scrollHeight);

      // console.log('scrollTop + (clientHeight + 50) >= scrollHeight', scrollTop + (clientHeight + 50) >= scrollHeight);

      if (scrollTop + (clientHeight + 50) >= scrollHeight) {
        if (!hasMore) return;

        setIsOnScrollLoading(true);
        setPage((prev) => prev + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [location, showCartPage, isOnScrollLoading, hasMore]);

  // START filters
  const [searchKeyword, setSearchKeyword] = useState('');
  const [range, setRange] = useState([0, 0]);
  const [sizes, setSizes] = useState([]);
  const [stockOnly, setStockOnly] = useState(false);

  const handelSizeChange = (v) =>{
    setSizes((oldSizes) => {
      let newSizes = oldSizes;
      const exist = oldSizes?.some((s) => s == v);

      if (exist) {
        newSizes = oldSizes?.filter((s) => s !== v);
      } else {
        newSizes = [...newSizes, ...[v]];
      }

      return newSizes;
    });
  };

  useEffect(() => {
    (async () => {
      await callProductApi();

      console.log('page changed');
    })();
  }, [page]);

  const resetToFirstPage = ()=>{
    setShowProductSkeletonState(true);
    setPage(null);

    const timer = setTimeout(() => {
      (async () => {
        setPage(1);
      })();
    }, 500);

    return () => clearTimeout(timer);
  };

  useEffect(() => {
    if (sizes == null) return;

    resetToFirstPage();
  }, [sizes]);

  useEffect(() => {
    if (range == null) return;

    resetToFirstPage();
  }, [range]);

  useEffect(() => {
    console.log('activeCategory', activeCategory);
    if (activeCategory == null) return;

    resetToFirstPage();
  }, [activeCategory]);

  useEffect(() => {
    if (searchKeyword == null) return;

    resetToFirstPage();
  }, [searchKeyword]);

  useEffect(() => {
    resetToFirstPage();
  }, [stockOnly]);
  // END filters

  const getProductDetail = async (sku)=>{
    try {
      const res = await apiGet({
        uri: `Products/Sku?sku=${sku || ''}`,
        msal: {
          instance: instance,
          accounts: accounts,
        },
      });

      return res;
    } catch (error) {
      return false;
    }
  };

  const callAddToCartApi = async (cartParams, cartOptions) =>{
    try {
      setShowCartBtnLoading(true);

      let {sku, isRemoveItem = false} = cartParams;
      const {decrease} = cartOptions;
      let currentCartItem = cartItems?.find((ct) => ct?.sku == sku);
      const productDetail = await getProductDetail(sku);
      let isReplaceStock = false;

      // check product is still available
      if (productDetail?.status == 0) {
        isRemoveItem = true;

        Swal.fire(invalidStockInCart);
      }

      // check if stock is 0
      if (productDetail?.stock == 0) {
        isRemoveItem = true;
        Swal.fire(outOfStockSwal);
      }

      // check available stock, skip remove and decrease qty case
      if (currentCartItem?.qty >= productDetail?.stock && !isRemoveItem && !decrease) {
        isReplaceStock = true;
        Swal.fire(outOfStockSwal);
      }

      if (currentCartItem) {
        currentCartItem = {...currentCartItem, qty: decrease ? ((currentCartItem?.qty - defaultQty) < 0 ? 0 : currentCartItem?.qty - defaultQty) : (currentCartItem?.qty + defaultQty)};
      } else {
        currentCartItem = {sku, qty: defaultQty};
      }

      // to remove item from cart
      if (isRemoveItem) {
        currentCartItem = {...currentCartItem, qty: 0};
      }

      // replace to available stock
      if (isReplaceStock) {
        currentCartItem = {...currentCartItem, qty: productDetail?.stock};
      }

      const otherCartItems = cartItems?.filter((ct) => ct?.sku != sku);
      const mergedCartItems = [...otherCartItems, ...[currentCartItem]];

      const getCarts = await apiPost({
        uri: 'Cart/update',
        msal: {
          instance: instance,
          accounts: accounts,
        },
        payload: {
          employeeId: slsHrMobileData?.authUser?.employeeId || '',
          cart1: mergedCartItems?.map((v) =>{
            return {
              sku: v?.sku,
              qty: v?.qty,
            };
          }),
        },
      });

      if (getCarts?.cart1) {
        const [cartItemsRes] = await callCartApi({toShowProductSkeleton: false});
        const newProducts = await mapProductStockWithCartStock({productsParam: products, cartItemsParam: cartItemsRes});

        setProducts(newProducts);
      } else {
        setCartItems([]);
      }

      setTimeout(() => {
        setShowCartBtnLoading(false);
      }, 1000);
    } catch (error) {
      dispatch(setFullLoading(true));
      console.error(error);
    }
  };

  const mapProductStockWithCartStock = async ({productsParam, cartItemsParam} = {}) =>{
    try {
      if (!cartItemsParam?.length) {
        return productsParam;
      }

      return productsParam?.map((p) => {
        const currentCartItem = cartItemsParam?.find((ct) => ct?.sku == p?.sku);
        let newStock = p?.stock;

        if (currentCartItem) {
          newStock = p?.stock - currentCartItem?.qty;
        }

        return {
          ...p,
          stock: newStock <= 0 ? 0 : newStock,
        };
      }) || [];
    } catch (error) {
      dispatch(setFullLoading(true));
      console.error(error);
    }
  };

  const clearFilters = ()=> {
    setRange([0, 0]);
    setSizes([]);
    setStockOnly(false);
  };

  return (
    <>
      <div className={showCartPage ? 'd-block' : 'd-none'}>
        <Cart setShowCartBtnLoading={setShowCartBtnLoading} showCartBtnLoading={showCartBtnLoading} setShowCartPage={setShowCartPage} cartItems={cartItems} callAddToCartApi={callAddToCartApi} callCartApi={callCartApi} />
      </div>

      <div className={!showCartPage ? 'd-block' : 'd-none'}>
        <ProductDetailModal show={showDetailModal} onHide={() => {
          setShowDetailModal(false);
        }} product={currentModalProduct} callAddToCartApi={callAddToCartApi}/>

        <div className="home transform-right-to-left_">
          <div className={`nav-popup-filter-wrapper click-for-filter-popup`}>
            <ul className="list-group list-group-flush ignore-click-for-filter-popup">
              <li className="pb-0 list-group-item ignore-click-for-filter-popup ignore-hover" style={{border: 'none'}}>
                <span className='float-right text-danger pointer text-decoration-underline ignore-click-for-filter-popup'
                  style={{fontSize: '12px'}}
                  onClick={clearFilters}>{language?.clear?.[hrMobileLanguage]}</span>
              </li>

              <li className="pt-0 list-group-item ignore-click-for-filter-popup ignore-hover">
                <p>{language?.homePage?.Filter?.Range?.[hrMobileLanguage]}</p>

                <PriceRangeFilter range={range} setRange={setRange} filterMasterData={filterMasterData}/>
              </li>
              <li className="list-group-item ignore-click-for-filter-popup ignore-hover">
                <p>{language?.homePage?.Filter?.Size?.[hrMobileLanguage]}</p>

                <Row className='ms-1 ignore-click-for-filter-popup'>
                  {filterMasterData?.sizes?.some((s) => s.toLowerCase() == 'one size') && (
                    <Col className={`col-12 px-0`}>
                      <div className={`py-2 size-filter-box text-center ignore-click-for-filter-popup mb-1 ${sizes?.some((sv) => sv.toLowerCase() == 'one size') && 'active'}`}
                        onClick={()=>handelSizeChange('One size')}>
                        {language?.homePage?.Filter?.TextSize?.[hrMobileLanguage]}
                      </div>
                    </Col>
                  )}
                  {filterMasterData?.sizes?.filter((s) => s.toLowerCase() !== 'one size')?.map((v, i) => (
                    <Col className={`col-3 px-0 fs-12`} key={i}>
                      <div onClick={()=>handelSizeChange(v)} className={`py-2 size-filter-box text-center ignore-click-for-filter-popup mb-1 ${sizes?.some((sv) => sv == v) && 'active'}`} >
                        {v?.replace(',', '.')}
                      </div>
                    </Col>
                  ))}
                </Row>
              </li>
              <li className="list-group-item ignore-click-for-filter-popup ignore-hover">
                <p>{language?.homePage?.Filter?.stock?.[hrMobileLanguage]}</p>

                <Row className='ms-1 ignore-click-for-filter-popup'>
                  <Col className={`col-12 px-0 fs-12`}>
                    <div onClick={() => setStockOnly(!stockOnly)} className={`py-2 size-filter-box text-center ignore-click-for-filter-popup mb-1 ${stockOnly && 'active'}`} >
                      {language?.homePage?.Filter?.availableStock?.[hrMobileLanguage]}
                    </div>
                  </Col>
                </Row>
              </li>
            </ul>
          </div>

          <Row style={{maxHeight: '28px'}}>
            <Col className='col-6'>
              <span style={{cursor: 'pointer'}} onClick={backToHrMobileApp}>
                <MdArrowBackIosNew style={{fontSize: '26px', marginRight: '0.5rem'}} />
                {language?.homePage?.hrMobile?.[hrMobileLanguage]}
              </span>
            </Col>

            <Col className='col-6 nav-filter-wrapper prevent-select'>
              <NavFilter
                isSearchInputVisible={isSearchInputVisible}
                searchKeyword={searchKeyword}
                filterMasterData={filterMasterData}
                range={range}
                sizes={sizes}
                stockOnly={stockOnly}
                toggleSearchInput={toggleSearchInput}
                setSearchKeyword={setSearchKeyword} />
            </Col>
          </Row>

          <div className='category-wrapper mt-4'>
            <p className={`category-item ${activeCategory == 'all' && 'active'}`} onClick={async (e) => {
              setActiveCategory('all');
            }}>All</p>

            {!showCategorySkeleton ? (
              <>
                {categories?.map((c, i) => (
                  <p data-cy={`category-nav-menu-${c}`} className={`category-item ${activeCategory == c && 'active'}`} key={i} onClick={async (e) => {
                    setActiveCategory(c);
                  }} style={{textTransform: 'capitalize'}}>{c}</p>
                ))}
              </>
            ) : (
              <>
                <Skeleton animation="wave" sx={{bgcolor: '#F9F8F6'}} variant="rounded" width={70} height={20} className='ms-1 mt-1' />
                <Skeleton animation="wave" sx={{bgcolor: '#F9F8F6'}} variant="rounded" width={50} height={20} className='ms-3 mt-1' />
                <Skeleton animation="wave" sx={{bgcolor: '#F9F8F6'}} variant="rounded" width={100} height={20} className='ms-3 mt-1' />
                <Skeleton animation="wave" sx={{bgcolor: '#F9F8F6'}} variant="rounded" width={80} height={20} className='ms-3 mt-1' />
              </>
            )}
          </div>

          <div className='container my-3' style={{minHeight: '80vh'}}>
            <div className={`mt-2 px-2 ${showProductSkeletonState ? 'd-block' : 'd-none'}`}>
              <Row>
                {Array.from(Array(20).keys())?.map((v, i) => (
                  <Col xs={6} key={i} className='' style={{padding: '0 4px'}}>
                    <div style={{width: '100%', height: '240px'}}>
                      <Skeleton animation="wave" sx={{bgcolor: '#F9F8F6'}} variant="rounded" width={'100%'} height={'150px'} />
                      <Skeleton animation="wave" sx={{bgcolor: '#F9F8F6'}} variant="rounded" width={'30%'} height={18} className='mt-2'/>
                      <Skeleton animation="wave" sx={{bgcolor: '#F9F8F6'}} variant="rounded" width={'100%'} height={20} className='mt-2'/>
                      <Skeleton animation="wave" sx={{bgcolor: '#F9F8F6'}} variant="circular" width={30} height={30} className='mt-2 mb-3 me-2' style={{float: 'right'}}/>
                    </div>
                  </Col>
                ))}
              </Row>

              <div className='inline-btn-loader bg-dark mt-2 float-box-shadow to-sake-btn pe-none'>
                <Row>
                  <Col className='col-12 p-0'>
                    <span className="loader"></span>
                  </Col>
                </Row>
              </div>
            </div>

            <div className={`${showProductSkeletonState ? 'd-none' : 'd-block'}`}>
              <ProductItems products={products} callAddToCartApi={callAddToCartApi} setCurrentModalProduct={setCurrentModalProduct} setShowDetailModal={setShowDetailModal}/>

              {isOnScrollLoading && (
                <p className='text-center mb-5'>{language?.homePage?.LoadingMoreItems?.[hrMobileLanguage]}</p>
              )}

              {(products?.length != 0 && products?.length < 4) && (
                <Row>
                  {Array.from(Array(3 - products?.length).keys())?.map((v, i)=> (
                    <Col className='col-6' style={{height: '300px'}} key={i}></Col>
                  ))}
                </Row>
              )}

              <CartBtn
                products={products}
                promiseInProgress={promiseInProgress}
                isFullLoading={isFullLoading}
                showCartBtnLoading={showCartBtnLoading}
                cartItems={cartItems}
                showProductSkeletonState={showProductSkeletonState}
                setShowCartPage={setShowCartPage}
              />
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
