import {Modal} from 'react-bootstrap';

const TermsAndConditionsModal = (props)=>{
  return (
    <>
      <Modal
        show={props?.showModal}
        onHide={() => {
          props?.setShowModal(false);
        }}
        fullscreen={true}
      >
        <Modal.Header closeButton className='mt-2'>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>1. Introduction</p>
          <p style={{textAlign: 'justify'}}>{`These Terms and Conditions ("T&amp;C") govern the Customer Discount Program (the "Program") offered by Pandora Services Co., Ltd. ("Company") and its affiliate &ldquo;Pandora Production Co., Ltd.&rdquo; to its employees and employees of affiliated companies ("Customer") for the purchase of affordable jewelry products under the brand &ldquo;PANDORA&rdquo; ("Products") at a special price (&ldquo;Price&rdquo;). By participating in this Program, Customers agree to abide by these T&amp;C. The Program is subject to change or termination at the discretion of the Company.`}</p>
          <p>2. Definitions</p>

          <>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>“Company” means Pandora Services Co., Ltd.</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    “Customer” means employee of Pandora Services Co., Ltd. and Pandora
    production Co., Ltd.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    “Price” has the meaning set out in clause 1 above.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    “Product” has the meaning set out in clause 1 above.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    “Program” has the meaning set out in clause 1 above.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    “Personal Use” means for Customer’s own use, or for the use of another
    person who receive such Product from Customer as gift, and not for further
    resale or distribution in any manner.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    “T&amp;C” means the terms and conditions set forth herein.
            </p>
          </>

          <>
            <p style={{textAlign: 'justify'}}>3. Eligibility and Account Usage</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    3.1. Participation in the Program is limited to current employees of Pandora
    Services Co., Ltd. or affiliated companies.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    3.2. Customers are required to use their individual and dedicated accounts,
    specifically created for their participation in the Program. The use of
    other customers’ accounts, impersonation, or any form of account sharing is
    strictly prohibited. Any violations of this policy shall result in account
    suspension or other actions deemed appropriate by the Company.
            </p>
            <p style={{textAlign: 'justify'}}>4. Price</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    4.1. The Price offered to Customers under this Program is specified by the
    Company and subject to change without notice.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    4.2. The Price is applicable only to Products as specified by the Company and
    may not be combined with other promotions, discounts, or offers unless
    explicitly stated.
            </p>
            <p style={{textAlign: 'justify'}}>5. Purchase Method</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    5.1. The purchase method for acquiring Products through the Program is
    determined by the Company, and it may be subject to change as announced by
    the Company.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    5.2. Customers acknowledge that this purchase method is the exclusive and
    sole channel for obtaining Products at the Price. There are no alternative
    channels or means to access the Price.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    5.3. The {`Company's`} decision regarding the purchase method is final and
    binding. Customers are encouraged to stay informed about any changes or
    updates related to the purchase method as communicated by the Company
            </p>
          </>

          <>
            <p style={{textAlign: 'justify'}}>6. Maintenance</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    6.1. The Company reserves the right to perform maintenance on the application
    or purchase channel used for the Program. This maintenance may include
    updates, improvements, or system enhancements to ensure the efficient and
    secure operation of the purchase method.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    6.2. Customers will be duly informed of any scheduled maintenance, and the
    Company will make reasonable efforts to minimize disruptions during such
    maintenance. In cases of unscheduled maintenance or downtime, the Company
    will work to restore the purchase method as swiftly as possible.
            </p>
            <p style={{textAlign: 'justify'}}>7. Inspection upon Receipt</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    7.1. Customers acknowledge that the Products will be delivered in an as-is
    condition. The Customers must inspect Products upon receipt. Any defects or
    issues found after acceptance will not be eligible for return, to the extent
    permitted by applicable laws.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    7.2. In the case that a defect is found at the pick-up point, Customer shall
    comply with the return policy announced by the Company.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    7.3. Customers acknowledge that, due to their involvement in the production
    process and their familiarity with quality standards of the Products, the
    advertising photos provided are for promotional purposes only and may not
    accurately represent the actual product received and that no guarantee of
    satisfaction is offered, and no returns will be accepted in case of
    dissatisfaction.<span className="Apple-converted-space">&nbsp;</span>
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
              <span className="Apple-converted-space">7.4 </span>For defects or mechanical
    issues, the Company provides a warranty for defective Products in accordance
    with applicable laws.
            </p>
          </>

          <>
            <p style={{textAlign: 'justify'}}>8. Misuse of the Program</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    8.1 The Products purchased through this Program are intended for Personal
    Use by the Customer and are not to be resold, distributed, or used for any
    commercial purpose including advertisement. This prohibition includes, but
    is not limited to, reselling the Products to third parties, using the
    Products in a commercial venture, or incorporating them into products
    intended for commercial sale.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    8.2 Any violation of this clause may result in the revocation of the
    privileges under this Program.
            </p>
            <p style={{textAlign: 'justify'}}>9. Data Privacy</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    By participating in the Program, Customers acknowledge and agree that the
    Company may collect and use their personal information for Program
    administration and communication purposes. The Company will handle such
    information in accordance with applicable privacy laws and its privacy
    policy.
            </p>
            <p style={{textAlign: 'justify'}}>10. Purchase Limitation</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    10.1. The limitation on the number of Products a Customer can purchase will
    be determined by the Company and may be subject to change in the future, as
    announced by the Company.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    10.2. As this is a special program with special prices, Customers are
    strictly prohibited from reselling or engaging in any commercial activities
    with the purchased Products.
            </p>
            <p style={{textAlign: 'justify'}}>11. Suspension of Account</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    11.1. In the event that the Company suspects that any Employee has violated
    the T&amp;C, the Company reserves the right to suspend the {`Employee's`}
    account without prior notice.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    11.2 The suspension will remain in effect until the Company has completed an
    investigation to address the concerns raised and determined that no
    violation of the T&amp;C has occurred by the Employee.
            </p>
          </>

          <>
            <p style={{textAlign: 'justify'}}>
    12. Termination of Suspension of the Program
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    12.1. This Program is offered as a benefit to employees of the Company.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    12.2. The Company reserves the right to terminate or temporarily suspend
    this Program at its discretion, for any reasons deemed appropriate by the
    Company.
            </p>
            <p style={{textAlign: 'justify'}}>13. Contact Information</p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>
    For inquiries or assistance related to the Program, Customers may contact
    the {`Company's`} HR department or the designated Program administrator.
            </p>
            <p style={{paddingLeft: 40, textAlign: 'justify'}}>&nbsp;</p>
            <hr />
            <p style={{textAlign: 'right'}}>Classification: <span className='fw-bold'>Pandora Internal</span></p>
          </>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default TermsAndConditionsModal;
