import Excel from 'exceljs';
import * as FileSaver from 'file-saver';
import {SiMicrosoftexcel} from 'react-icons/si';
import Swal from 'sweetalert2';
import {apiPost} from '../../apis';
import {useMsal} from '@azure/msal-react';
import moment from 'moment';

export const ExportSaleOrders = (props)=>{
  const {instance, accounts} = useMsal();

  const prepareSheetOne = ({workbook, exportData})=>{
    const sheet = workbook.addWorksheet('Header');

    const header = [
      'ORDERNUMBER',
      'CustAccount',
      'DeliveryName',
      'DeliveryStreet',
      'DeliveryCity',
      'DeliveryState',
      'DeliveryZIPCode',
      'DeliveryCountryRegionID',
      'PurchOrderFormNum',
      'SalesPoolId',
      'smmCampaignId',
      'Payment',
      'EndDisc',
      'CustomerRef',
    ];

    const excelData = exportData?.map((d) => {
      return {
        'ORDERNUMBER': d?.orderNumber,
        'CustAccount': d?.employeeId,
        'DeliveryName': '',
        'DeliveryStreet': '',
        'DeliveryCity': '',
        'DeliveryState': '',
        'DeliveryZIPCode': '',
        'DeliveryCountryRegionID': '',
        'PurchOrderFormNum': d?.orderNumber,
        'SalesPoolId': '',
        'smmCampaignId': '',
        'Payment': '',
        'EndDisc': '',
        'CustomerRef': '',
      };
    });

    sheet.addRow(header);
    for (const content of excelData) {
      sheet.addRow(Object.values(content));
    }

    sheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'cccccc'},
    };

    return workbook;
  };

  const prepareSheetTwo = ({workbook, exportData})=>{
    const sheet = workbook.addWorksheet('Lines');

    const header = [
      'ORDERNUMBER',
      'InventSiteId',
      'ItemId',
      'SalesQty',
      'LineDisc',
      'Barcode',
      'InventLocationId',
      'SalesPrice',
      'LinePercent',
      'ShippingDateRequested',
      'SalesLineOCP.SalesLeadTimeInWeeks',
      'PurchOrderFormNum',
    ];

    const orderDetails = [];

    for (const d of exportData) {
      orderDetails.push(...d?.orderDetails);
    }

    const excelData = orderDetails?.map((d) => {
      return {
        'ORDERNUMBER': d?.orderNumber,
        'InventSiteId': '',
        'ItemId': d?.sku,
        'SalesQty': d?.qty || 0,
        'LineDisc': '',
        'Barcode': '',
        'InventLocationId': '',
        'SalesPrice': d?.price?.toLocaleString('en-US') || 0,
        'LinePercent': '',
        'ShippingDateRequested': moment(d?.createdAt).add(14, 'days').format('DD/MM/YYYY'),
        'SalesLineOCP.SalesLeadTimeInWeeks': 1,
        'PurchOrderFormNum': d?.orderNumber,
      };
    });

    sheet.addRow(header);
    for (const content of excelData) {
      sheet.addRow(Object.values(content));
    }

    sheet.getRow(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: 'cccccc'},
    };

    return workbook;
  };

  const exportExcel = () => {
    Swal.fire({
      title: 'Enter limit to export.',
      input: 'number',
      inputAttributes: {
        autocapitalize: 'off',
      },
      confirmButtonText: 'Export',
      confirmButtonColor: 'green',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (value <= 0) {
          return 'Enter valid number!';
        }
      },
      preConfirm: async (limit) => {
        try {
          const json = await apiPost({
            uri: `Admin/order/list`,
            payload: {
              limit,
            },
            msal: {
              instance: instance,
              accounts: accounts,
            },
          });

          return json;
        } catch (error) {
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (!result?.isConfirmed) return;

      const workbook = new Excel.Workbook();

      prepareSheetOne({workbook, exportData: result?.value?.orders || []});
      prepareSheetTwo({workbook, exportData: result?.value?.orders || []});

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        FileSaver.saveAs(blob, 'sales_order.xlsx');
      });
    });
  };

  return (
    <div className='d-flex align-items-center justify-content-center admin-btn admin-btn-green w-100' onClick={exportExcel}>
      <SiMicrosoftexcel size={'20px'} className='me-2'/>Export
    </div>
  );
};
