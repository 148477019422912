import {Col, Modal, Row} from 'react-bootstrap';
import {status} from '../../types/limitation-setting';
import {useEffect, useState} from 'react';
import {trackPromise} from 'react-promise-tracker';
import {useDispatch} from 'react-redux';
import {useMsal} from '@azure/msal-react';
import {apiPost} from '../../apis';
import {setFullLoading} from '../../store/appSlice';
import Swal from 'sweetalert2';

export const ModifySettingModal = (props)=>{
  const dispatch = useDispatch();
  const {instance, accounts} = useMsal();

  const [itemInput, setItemInput] = useState(0);
  const [saleOrderInput, setSaleOrderInput] = useState(0);
  const [statusInput, setStatusInput] = useState(status.active);
  const [canSubmit, setCanSubmit] = useState(false);

  useEffect(() => {
    setCanSubmit(false);

    setItemInput(0);
    setSaleOrderInput(0);
    setStatusInput(status.active);
  }, [props?.show]);

  useEffect(() => {
    if (!props?.settingDetail) return;

    setItemInput(parseInt(props?.settingDetail?.limitationItem));
    setSaleOrderInput(parseInt(props?.settingDetail?.limitationSaleOrder));
    setStatusInput(parseInt(props?.settingDetail?.status));
  }, [props?.settingDetail]);

  useEffect(() => {
    if (props?.show) return;

    props?.setSettingDetail(null);
  }, [props]);

  useEffect(() => {
    setCanSubmit(false);

    if (itemInput) {
      setCanSubmit(true);
    }
  }, [itemInput]);

  const submit = ()=>{
    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));
            let payload = {
              'limitationItem': itemInput,
              'limitationSaleOrder': saleOrderInput,
              'status': statusInput,
            };

            if (props?.settingDetail) {
              payload = {
                ...payload,
                'id': props?.settingDetail?.id,
              };
            }

            await apiPost({
              uri: `Admin/limitation/create`,
              payload,
              msal: {
                instance: instance,
                accounts: accounts,
              },
            });

            props?.onHide();

            Swal.fire({
              title: 'Success!',
              text: 'Updated successfully!',
              icon: 'success',
              confirmButtonText: 'OK',
            });

            dispatch(setFullLoading(false));
          } catch (error) {
            dispatch(setFullLoading(true));
            console.error(error);
          }
        })(),
    );
  };

  return (
    <Modal
      show={props?.show}
      onHide={props?.onHide}
      centered
      className=""
    >
      <Modal.Header closeButton className='mt-2'>
        <Modal.Title>Modify Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <label htmlFor="input-1" className="form-label">Item Limit</label>
          <input type="number" className="form-control admin-form-control" id="input-1" autoComplete='off'
            value={itemInput} onChange={(e) => setItemInput(e.target.value)} />
        </div>
        <div className="mb-3">
          <label htmlFor="input-2" className="form-label">Order Export Limit</label>
          <input type="number" className="form-control admin-form-control" id="input-2" autoComplete='off'
            value={saleOrderInput} onChange={(e) => setSaleOrderInput(e.target.value)} />
        </div>
        <div className="mb-5">
          <label htmlFor="input-2" className="form-label">Status</label>
          <select className="form-select admin-form-select mb-3"
            value={statusInput} onChange={(e) => setStatusInput(e.target.value)}>
            <option value={status.active}>Active</option>
            <option value={status.inactive}>Inactive</option>
          </select>
        </div>

        {canSubmit && (
          <Row>
            <Col xs={6}></Col>
            <Col xs={6}>
              <div className='admin-btn admin-btn-primary w-100 d-flex align-items-center justify-content-center'
                style={{cursor: 'pointer'}} onClick={submit}>Save</div>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
};
