import {Col, Row} from 'react-bootstrap';
import {useEffect, useMemo, useState} from 'react';
import {MdArrowBackIosNew} from 'react-icons/md';
import {QueryClient, QueryClientProvider, useQuery} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import {useMsal} from '@azure/msal-react';
import {setFullLoading} from '../../store/appSlice';
import {apiGet} from '../../apis';
import MaterialReactTable from 'material-react-table';
import {dataTableSettings} from '../../helpers/data-table-settings';
import {syncLogStatusMappings, syncLogTypeMappings} from '../../types/sync-log';

const SyncLogDetailTable = (props) =>{
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const {instance, accounts} = useMsal();
  const [syncLogDetails, setSyncLogDetails] = useState();

  const columns = useMemo(
      () => [
        {
          accessorKey: 'sku',
          header: 'Sku',
          size: 0,
        },
        {
          accessorKey: 'resultMsg',
          header: 'Message',
          size: 0,
        },
        {
          header: 'Status',
          size: 0,
          accessorFn: (row) =>{
            return <span style={{
              color: syncLogStatusMappings?.find((v) => v?.key == row?.status)?.color,
            }}>{syncLogStatusMappings?.find((v) => v?.key == row?.status)?.displayLabel}</span>;
          },
        },
      ],
      [],
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {isError, isFetching, isLoading} = useQuery({
    queryKey: [
      'table-data',
      pagination.pageIndex,
      pagination.pageSize,
    ],

    queryFn: async () => {
      try {
        dispatch(setFullLoading(true));

        const json = await apiGet({
          uri: `Admin/logs/product/detail?SyncId=${props?.syncIdForApi}&offset=${pagination.pageIndex * pagination.pageSize}&limit=${pagination.pageSize}`,
          msal: {
            instance: instance,
            accounts: accounts,
          },
        });

        setSyncLogDetails(json);
        dispatch(setFullLoading(false));

        return json;
      } catch (error) {
        dispatch(setFullLoading(true));
        console.error('queryFn error', error);
      }
    },
  });

  return (
    <Row className='sync-log-detail'>
      <Col xs={12}>
        <div className='mb-4 pointer d-flex align-items-center' onClick={() => props?.setSyncIdForApi(null)}>
          <MdArrowBackIosNew size={'20px'} />
          <span className='ms-2'>{syncLogTypeMappings?.find((v) => v.key == syncLogDetails?.syncLog?.syncType)?.displayLabel} Sync Log List</span>
        </div>

        <div className='custom-card px-4 py-4 mb-5 mt-2'>
          {syncLogTypeMappings?.find((v) => v.key == syncLogDetails?.syncLog?.syncType)?.displayLabel} Synced Log Details
        </div>

        <MaterialReactTable
          muiTablePaginationProps={{
            rowsPerPageOptions: [
              {label: '5', value: 5},
              {label: '10', value: 10},
              {label: 'all', value: syncLogDetails?.total || 0},
            ],
          }}
          enableGlobalFilter={false}
          columns={columns}
          data={syncLogDetails?.logsDetails || []}
          enableColumnFilters={false}
          enableSorting={false}
          manualFiltering
          manualPagination
          manualSorting
          muiToolbarAlertBannerProps={
                  isError ?
                    {
                      color: 'error',
                      children: 'Error loading data',
                    } :
                    undefined
          }
          onPaginationChange={setPagination}
          rowCount={syncLogDetails?.total || 0}
          state={{
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isFetching,
          }}
        />
      </Col>
    </Row>
  );
};

const queryClient = new QueryClient(dataTableSettings);

const SyncLogDetail = (props) => (
  <QueryClientProvider client={queryClient}>
    <SyncLogDetailTable {...props}/>
  </QueryClientProvider>
);

export default SyncLogDetail;
