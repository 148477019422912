import {language} from '../../language';
import {getCurrentLanguage} from '../language-switcher';

const slsHrMobileLanguage = getCurrentLanguage();

export const confirmDeleteItemSwal = {
  title: language?.homePage?.Warning?.[slsHrMobileLanguage],
  text: language?.homePage?.WarningRemove?.[slsHrMobileLanguage],
  icon: 'warning',
  confirmButtonText: language?.homePage?.btnOK?.[slsHrMobileLanguage],
  cancelButtonText: language?.homePage?.btnCancel?.[slsHrMobileLanguage],
  confirmButtonColor: '#F97C89',
  showCancelButton: true,
  allowOutsideClick: false,
  allowEscapeKey: false,
  focusCancel: true,
};

export const outOfStockSwal = {
  title: language?.homePage?.Warning?.[slsHrMobileLanguage],
  text: language?.homePage?.ProductOutOfStock?.[slsHrMobileLanguage],
  icon: 'warning',
  confirmButtonText: language?.homePage?.btnOK?.[slsHrMobileLanguage],
  cancelButtonText: language?.homePage?.btnCancel?.[slsHrMobileLanguage],
  confirmButtonColor: 'black',
  allowOutsideClick: false,
  allowEscapeKey: false,
};

export const invalidStockInCart = {
  title: language?.warning?.[slsHrMobileLanguage],
  text: language?.checkoutItemsNotAvailable?.[slsHrMobileLanguage],
  icon: 'warning',
  confirmButtonText: language?.ok?.[slsHrMobileLanguage],
  confirmButtonColor: 'black',
  allowOutsideClick: false,
  allowEscapeKey: false,
};

export const paymentOptionFail = {
  icon: 'warning',
  title: language?.warning?.[slsHrMobileLanguage],
  text: language?.payAgainMsg?.[slsHrMobileLanguage],
  confirmButtonText: language?.pay?.[slsHrMobileLanguage],
  showCancelButton: true,
  cancelButtonText: language?.cancel?.[slsHrMobileLanguage],
  confirmButtonColor: 'black',
  allowOutsideClick: false,
  allowEscapeKey: false,
};
