import {Col, Row} from 'react-bootstrap';
import {setFullLoading} from '../../store/appSlice';
import {useDispatch, useSelector} from 'react-redux';
import {apiGet} from '../../apis';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {ProductLazyLoadImg} from '../../components/user/ProductImg';
import {useMsal} from '@azure/msal-react';
import {language} from '../../language';

const ProductItems = (props)=>{
  const {instance, accounts} = useMsal();
  const dispatch = useDispatch();
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);

  const callProductDetailApi = async (product) =>{
    try {
      props?.setCurrentModalProduct(null);
      dispatch(setFullLoading(true));

      const getProduct = await apiGet({
        uri: `Products/Sku?sku=${product?.sku || ''}`,
        msal: {
          instance: instance,
          accounts: accounts,
        },
      });

      props?.setCurrentModalProduct(getProduct);
      props?.setShowDetailModal(true);

      dispatch(setFullLoading(false));
    } catch (error) {
      dispatch(setFullLoading(true));
      console.error(error);
    }
  };

  return (
    <Row className="product-list">
      {props?.products?.map((p, i) => (
        <Col className='col-6 ' key={i}>
          <div className={`item ${p?.stock == 0 && 'item-out-of-stock'}`} id={`item-${i}`} onClick={async () => {
            await callProductDetailApi(p);
          }}>
            {p?.stock == 0 && (
              <small className='out-of-stock-product-box'>{language?.homePage?.Cart?.OutOfStock?.[hrMobileLanguage]}</small>
            )}
            <ProductLazyLoadImg className={`item-img`} imgId={p?.image} />
            <h6 className='m-0' style={{fontWeight: 'bold'}}>{p?.price?.toLocaleString('en-US') || 0} ฿</h6>
            <p className='d-inline-block text-truncate m-0' style={{maxWidth: '100%'}}>{p?.productName}</p>

            <div style={{marginLeft: '74%'}} className=''>
              <div className='add-to-cart-icon' data-cy={`add-to-cart-icon-${i}`} onClick={async (e) => {
                e.preventDefault();
                e.stopPropagation();

                if (p?.hasChild && p?.size?.toLowerCase() != 'one size') {
                  await callProductDetailApi(p);

                  return;
                }

                await props?.callAddToCartApi({sku: p?.sku, stock: p?.stock}, {});
              }}>
                <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.05" x="1" y="1" width="40" height="40" rx="20" stroke="black" />
                  <rect x="15" y="20" width="12" height="2" rx="1" fill="black" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M22 15.75V26.25C22 26.6642 21.5523 27 21 27C20.4477 27 20 26.6642 20 26.25V15.75C20 15.3358 20.4477 15 21 15C21.5523 15 22 15.3358 22 15.75Z" fill="black" />
                </svg>

              </div>

            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default ProductItems;
