import {Col, Row} from 'react-bootstrap';
import {RxHamburgerMenu} from 'react-icons/rx';
import {useSelector} from 'react-redux';

const NavBar = (props)=>{
  const authUser = useSelector((state) => state.auth.auth);

  return (
    <Row>
      <Col className='col-12 navbar' style={{minHeight: '76px'}}>
        <Row className='w-100'>
          <Col className='col-6 d-flex align-items-center'>
            <RxHamburgerMenu onClick={() => {
              props?.setIsBarOpen(!props?.isBarOpen);
            }}
            className={`sidebar-toggle-btn ${props?.isBarOpen ? 'opened' : 'closed'}`} />
          </Col>
          <Col className='col-6'>
            <div className='profile-nav'>
              <span className='fw-bold'>
                {authUser?.displayName}
              </span>
            </div>
          </Col>
        </Row>

      </Col>
    </Row>
  );
};

export default NavBar;
