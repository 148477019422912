import {useEffect, useState} from 'react';
import {LuSearchX} from 'react-icons/lu';
import {useNavigate} from 'react-router-dom';

const NavFilter = (props)=>{
  const navigate = useNavigate();
  const [showFilterDot, setShowFilterDot] = useState(false);

  useEffect(() => {
    if ((props?.range[0] && props?.range[0] !== props?.filterMasterData?.priceRange?.minPrice) ||
    (props?.range[1] && props?.range[1] !== props?.filterMasterData?.priceRange?.maxPrice) ||
    props?.sizes?.length > 0 || props?.stockOnly) {
      setShowFilterDot(true);
    } else {
      setShowFilterDot(false);
    }
  }, [props]);

  return (
    <>
      {!props?.isSearchInputVisible && (
        <>
          <span data-cy="order-history-icon" className='ms-3' style={{cursor: 'pointer'}} onClick={() => navigate(`/order-histories`)}>
            <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M3.16665 3.91395C4.5836 2.24443 6.48503 1.02045 8.66915 0.435215C14.5039 -1.12821 20.5014 2.33441 22.0648 8.1692C23.6282 14.004 20.1656 20.0014 14.3308 21.5648C8.49603 23.1283 2.49859 19.6656 0.935167 13.8309C0.786269 13.2752 1.11604 12.704 1.67174 12.5551C2.22743 12.4062 2.79861 12.736 2.94751 13.2917C4.21314 18.0151 9.06821 20.8181 13.7916 19.5525C18.515 18.2869 21.3181 13.4318 20.0524 8.7084C18.7868 3.98501 13.9318 1.18193 9.20835 2.44756C7.2158 2.98146 5.51653 4.16939 4.33904 5.7917H6.29165C6.86694 5.7917 7.33331 6.25807 7.33331 6.83336C7.33331 7.40866 6.86694 7.87503 6.29165 7.87503H3.16665C2.01605 7.87503 1.08331 6.94229 1.08331 5.7917V2.6667C1.08331 2.0914 1.54968 1.62503 2.12498 1.62503C2.70028 1.62503 3.16665 2.0914 3.16665 2.6667V3.91395ZM12.5416 5.27086V10.4425L16.7653 13.2583C17.244 13.5774 17.3733 14.2242 17.0542 14.7028C16.7351 15.1815 16.0883 15.3109 15.6097 14.9917L10.9222 11.8667C10.6324 11.6736 10.4583 11.3483 10.4583 11V5.27086C10.4583 4.69557 10.9247 4.2292 11.5 4.2292C12.0753 4.2292 12.5416 4.69557 12.5416 5.27086Z" fill="black" />
            </svg>
          </span>

          <span className='ms-3 click-for-filter-popup' style={{cursor: 'pointer', position: 'relative'}} >
            {showFilterDot && (
              <div className="bs-blinking-dot dot-for-filter pulse" role="status"></div>
            )}
            <svg width="24" className='click-for-filter-popup' height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="3.43329" className='click-for-filter-popup' width="2.2" height="11" rx="1.1" fill="black" />
              <rect x="3.43329" className='click-for-filter-popup' y="13.2" width="2.2" height="8.8" rx="1.1" fill="black" />
              <circle cx="4.53331" className='click-for-filter-popup' cy="7.7" r="3.2" fill="white" stroke="black" strokeWidth="2" />
              <rect x="17.7333" className='click-for-filter-popup' y="11" width="2.2" height="11" rx="1.1" fill="black" />
              <rect x="17.7333" className='click-for-filter-popup' width="2.2" height="8.8" rx="1.1" fill="black" />
              <circle cx="18.8333" className='click-for-filter-popup' cy="14.3001" r="3.2" fill="white" stroke="black" strokeWidth="2" />
            </svg>
          </span>
        </>
      )}

      <div style={{position: 'relative'}}>
        <input type="text" className={`form-control search-input ${props?.isSearchInputVisible ? 'show-search-input' : 'hide-search-input'}`} value={props?.searchKeyword} onChange={(e) => {
          props?.setSearchKeyword(e.target.value);
        }} />

        {props?.isSearchInputVisible ? (
            <LuSearchX className={`ms-3 ${props?.isSearchInputVisible && 'show-search-icon'}`} style={{fontSize: '30px', cursor: 'pointer', top: '2px', right: '8px'}} onClick={props?.toggleSearchInput} />
        ) : (
            <span className={`ms-3 ${props?.isSearchInputVisible && 'show-search-icon'}`} style={{cursor: 'pointer', position: 'relative'}} onClick={props?.toggleSearchInput}>
              {props?.searchKeyword && (
                <div className="bs-blinking-dot dot-for-search pulse" role="status"></div>
              )}

              <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.131 18.0143C14.8387 18.0143 18.6953 14.2723 18.6953 9.60719C18.6953 4.94211 14.8387 1.20005 10.131 1.20005C5.42323 1.20005 1.56669 4.94211 1.56669 9.60719C1.56669 14.2723 5.42323 18.0143 10.131 18.0143Z" stroke="black" strokeWidth="2.2" />
                <path d="M16.9167 16.25L21.6667 20.9" stroke="black" strokeWidth="2" strokeLinecap="round" />
              </svg>
            </span>
        )}
      </div>
    </>
  );
};

export default NavFilter;
