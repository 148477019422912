import {Col, Row} from 'react-bootstrap';
import '../../styles/user/main.scss';
import '../../styles/user/post-order.scss';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setFullLoading} from '../../store/appSlice';
import logo from '../../assets/images/post-order/logo.svg';
import {apiGet} from '../../apis';
import {orderStatus} from '../../types/order';
import {language} from '../../language';
import {useMsal} from '@azure/msal-react';
import {QueryClient, QueryClientProvider, useQuery} from '@tanstack/react-query';
import {dataTableSettings} from '../../helpers/data-table-settings';
import {useNavigate} from 'react-router-dom';

const Render = (props) => {
  const {instance, accounts} = useMsal();
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(window.location.search);

  const {data: orderDetail} = useQuery({
    queryKey: [queryParams.get('orderNumber')],
    queryFn: async () => {
      if (!queryParams.get('orderNumber')) return;

      try {
        dispatch(setFullLoading(true));

        const getOrder = await apiGet({
          uri: `Order/detail?orderNumber=${queryParams.get('orderNumber') || ''}`,
          msal: {
            instance: instance,
            accounts: accounts,
          },
        });

        dispatch(setFullLoading(false));
        return getOrder;
      } catch (error) {
        dispatch(setFullLoading(true));
        console.error(error);
      }
    },
  });

  if (!orderDetail) {
    return <></>;
  }

  return (
    <>
      <div className="post-order">
        <div className='container my-3'>
          <div className='d-flex align-items-center justify-content-center'>
            <img src={logo} className='w-75' />
          </div>

          <div className='text-center message-area mt-5'>
            <div>
              <h1 style={{fontWeight: 'bold'}}>{orderStatus?.paid == orderDetail?.orderStatusId ? language?.thankYou?.[hrMobileLanguage] : language?.paymentFailed?.[hrMobileLanguage]}</h1>

              <div className='d-flex align-items-center justify-content-center mt-2'>
                <p className='px-2 text-center'>
                  {orderStatus?.paid == orderDetail?.orderStatusId ? language?.postPaymentSuccessMsg?.[hrMobileLanguage] : language?.postPaymentFailMsg?.[hrMobileLanguage]}
                </p>
              </div>

              <h4 style={{fontWeight: 'bold'}}>{orderDetail?.orderNumber}</h4>
            </div>
          </div>

          <div className={`px-5 py-2 default-btn animated-hover-btn mt-4 float-box-shadow bg-black`} onClick={() => navigate(`/`)}>
            <Row>
              <Col className='col-12 p-0'>
                <h3 className='text-white my-2 text-center'>{language?.ok?.[hrMobileLanguage]}</h3>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

const queryClient = new QueryClient(dataTableSettings);
const PostPayment = () => (
  <QueryClientProvider client={queryClient}>
    <Render />
  </QueryClientProvider>
);

export default PostPayment;
