import {Col, Row} from 'react-bootstrap';
import {bankingOptions} from '../../types/payment';
import kPlusIcon from '../../assets/images/bank-icons/k_plus.svg';
import bkkIcon from '../../assets/images/bank-icons/bkk.svg';
import ayudhyaIcon from '../../assets/images/bank-icons/ayudhya.svg';
import scbIcon from '../../assets/images/bank-icons/scb.svg';
import ktbIcon from '../../assets/images/bank-icons/ktb.svg';
import {language} from '../../language';
import {useSelector} from 'react-redux';
import {isMobile} from 'react-device-detect';

export const PaymentOptions = (props = {
  setShowBankingOptions: () => {},
  showBankingOptions: false,
  setBankOptionName: () => {},
  bankOptionName: '',
})=>{
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);

  return (
    <>
      <div className="form-check">
        <input className="form-check-input" data-cy="prompt-pay-radio" onClick={() => {
          props?.setShowBankingOptions(false);
          props?.setBankOptionName(null);
        }} type="radio" name="paymentType" id="promptPay1" defaultChecked={true} />
        <label className="form-check-label" htmlFor="promptPay1" onClick={() => {
          props?.setShowBankingOptions(false);
        }}>{language?.homePage?.Cart?.PromptPay?.[hrMobileLanguage]}</label>
      </div>

      {isMobile && (
        <>
          <div className="form-check mt-2">
            <input className="form-check-input" data-cy="banking-radio" onClick={() => (props?.setShowBankingOptions(true))} type="radio" name="paymentType" id="mobileBanking" />
            <label className="form-check-label" htmlFor="mobileBanking" onClick={() => (props?.setShowBankingOptions(true))}>{language?.homePage?.Cart?.MobileBanking?.[hrMobileLanguage]}</label>

            {props?.showBankingOptions && (
              <Row className='mt-4 mb-4'>
                <Col className='col'>
                  <img src={kPlusIcon} onClick={() => props?.setBankOptionName(bankingOptions.kbank)} className={`bank-icon pointer ${props?.bankOptionName == bankingOptions.kbank && 'active'}`} />
                </Col>
                <Col className='col'>
                  <img src={bkkIcon} onClick={() => props?.setBankOptionName(bankingOptions.bbl)} className={`bank-icon pointer ${props?.bankOptionName == bankingOptions.bbl && 'active'}`} />
                </Col>
                <Col className='col'>
                  <img src={ayudhyaIcon} onClick={() => props?.setBankOptionName(bankingOptions.bay)} className={`bank-icon pointer ${props?.bankOptionName == bankingOptions.bay && 'active'}`} />
                </Col>
                <Col className='col'>
                  <img src={scbIcon} onClick={() => props?.setBankOptionName(bankingOptions.scb)} className={`bank-icon pointer ${props?.bankOptionName == bankingOptions.scb && 'active'}`} />
                </Col>
                <Col className='col'>
                  <img src={ktbIcon} onClick={() => props?.setBankOptionName(bankingOptions.ktb)} className={`bank-icon pointer ${props?.bankOptionName == bankingOptions.ktb && 'active'}`} />
                </Col>
              </Row>
            )}
          </div>
        </>
      )}
    </>);
};
