import sls from 'react-secure-storage';

export const callMsal = async ({msal: {instance, accounts} = null, scopes = null}) => {
  let requestParams = {
    account: accounts[0],
    scopes: [process.env.REACT_APP_API_SCOPE1],
  };

  if (scopes) {
    requestParams = {
      ...requestParams,
      scopes: scopes,
    };
  }

  return new Promise((resolve, reject) => {
    instance
        .acquireTokenSilent(requestParams)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(`acquireTokenSilent error`, error);

          sls.clear();
          localStorage.clear();
          instance
              .logoutRedirect({postLogoutRedirectUri: process.env.PUBLIC_URL + '/back-office'})
              .catch((err) => console.log(err));
        });
  });
};
