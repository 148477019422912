import {Modal} from 'react-bootstrap';
import {ProductLazyLoadImg} from './user/ProductImg';

export const EnlargeProductImg = (props)=>{
  return (
    <Modal
      show={props?.showModal}
      onHide={() => {
        props?.setEnlargeProduct(null);
        props?.setShowModal(false);
      }}
      centered
      size='md'
    >
      <Modal.Header closeButton className='mt-2'>
        <Modal.Title>SKU: {props?.product?.sku}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ProductLazyLoadImg className={'w-100'} imgId={props?.product?.image} useLargeImg={true}/>
      </Modal.Body>
    </Modal>);
};
