import Modal from 'react-bootstrap/Modal';
import '../../styles/user/main.scss';
import '../../styles/user/product-detail-modal.scss';
import {Col, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import {ProductLazyLoadImg} from '../../components/user/ProductImg';
import {language} from '../../language';
// import sls from 'react-secure-storage';
import {useSelector} from 'react-redux';

function ProductDetailModal(props) {
  const [detailSku, setDetailSku] = useState();
  const [detailPrice, setDetailPrice] = useState();
  const [selectedSize, setSelectedSize] = useState();
  const [detailStock, setDetailStock] = useState(0);
  const [allowToAddToCart, setAllowToAddToCart] = useState(true);
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);

  useEffect(() => {
    setDetailSku(null);
    setDetailPrice(0);
    setSelectedSize(null);
    setDetailStock(0);

    // to set default selected when size is one size
    if (props?.product?.size?.toLowerCase() == 'one size') {
      const v = props?.product?.sizeOptions[0];
      setDetailSku(v?.sku);
      setDetailPrice(v?.price || 0);
      setSelectedSize(v?.size);
      setDetailStock(v?.stock);
    }
  }, [props]);

  useEffect(() => {
    if (selectedSize && detailStock == 0) {
      setAllowToAddToCart(false);
    } else {
      setAllowToAddToCart(true);
    }
  }, [selectedSize, detailStock]);

  return (
    <Modal
      show={props?.show}
      onHide={props?.onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="product-detail-modal"
      dialogClassName="max-width-430"
    >
      <Modal.Header closeButton className='pb-0 mt-2 d-flex align-items-center justify-content-center'>
        {props?.product?.stock == 0 && (
          <small style={{position: 'absolute', opacity: '.5'}} className="badge rounded-pill bg-pink text-dark">
            {language?.homePage?.Cart?.OutOfStock?.[hrMobileLanguage]}
          </small>
        )}
      </Modal.Header>
      <Modal.Body>

        <div style={{width: '300px', margin: 'auto'}}>
          <ProductLazyLoadImg className="img" imgId={props?.product?.image} useLargeImg={true}/>
        </div>

        <p className={`fw-bold price text-center ${detailSku ? 'visible' : 'invisible'}`}>{detailPrice ? detailPrice?.toLocaleString('en-US') : 0} ฿</p>
        <p className={`sku ${detailSku ? 'visible' : 'invisible'}`}>SKU: {detailSku}</p>
        <p className='product-name text-left mb-2' style={{fontWeight: 'bold'}}>{props?.product?.productName}</p>
        <p className=''>{language?.homePage?.Filter?.Size?.[hrMobileLanguage]}: </p>

        <Row className='ms-1 fs-12'>
          {props?.product?.sizeOptions?.map((v, i) => (
            <Col className={`${props?.product?.size?.toLowerCase() == 'one size' ? 'col-4' : 'col-2'} size-filter-box ${selectedSize == v?.size && 'active'}`} key={i}
              onClick={() => {
                setDetailSku(v?.sku);
                setDetailPrice(v?.price || 0);
                setSelectedSize(v?.size);
                setDetailStock(v?.stock);
              }}>{v?.size?.replace(',', '.')}</Col>
          ))}
        </Row>

        <div className='d-flex align-items-center justify-content-center my-4' onClick={async () => {
          if (!detailSku) {
            Swal.fire({
              title: language?.homePage?.Warning?.[hrMobileLanguage],
              text: language?.homePage?.selectSize?.[hrMobileLanguage],
              icon: 'warning',
              confirmButtonText: language?.homePage?.btnOK?.[hrMobileLanguage],
              cancelButtonText: language?.homePage?.btnCancel?.[hrMobileLanguage],
              confirmButtonColor: 'black',
            });

            return;
          }

          if (!allowToAddToCart) {
            return;
          }

          props.onHide();
          await props?.callAddToCartApi({sku: detailSku, stock: detailStock}, {decrease: false});
        }}>
          <div className={`px-5 py-2 close-btn w-75 box-shadow ${allowToAddToCart ? 'bg-dark' : 'bg-gray-disabled'}`}>
            <h6 className='text-white my-2 text-center'>
              <>
                {allowToAddToCart ? (
                  <>{language?.homePage?.Addtocart?.[hrMobileLanguage]}</>
                ) : (
                  <>{language?.homePage?.Cart?.OutOfStock?.[hrMobileLanguage]}</>
                )}
              </>
            </h6>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ProductDetailModal;
