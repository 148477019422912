import {createSlice} from '@reduxjs/toolkit';

const cartItemsSlice = createSlice({
  name: 'cartItems',
  initialState: [],
  reducers: {
    setCartItems: (state, action) => {
      return {
        ...state,
        auth: action.payload,
      };
    },
  },
});

export const {setCartItems} = cartItemsSlice.actions;
export default cartItemsSlice.reducer;
