import {useMemo, useState} from 'react';
import {setFullLoading} from '../../store/appSlice';
import {useDispatch} from 'react-redux';
import MaterialReactTable from 'material-react-table';
import {Col, Row} from 'react-bootstrap';
import {TextField} from '@mui/material';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import {apiGet, apiPost} from '../../apis/index';
import moment from 'moment';
import {BsEye} from 'react-icons/bs';
import {SaleOrderDetail} from '../../components/back-office/SaleOrderDetail';
import {trackPromise} from 'react-promise-tracker';
import {orderStatusMappings} from '../../types/order';
import {useMsal} from '@azure/msal-react';
import {ExportSaleOrders} from '../../components/back-office/ExportSaleOrders';
import {dataTableSettings} from '../../helpers/data-table-settings';
import {GoSync} from 'react-icons/go';
import Swal from 'sweetalert2';
import {formatDateTime} from '../../helpers/datetime';

const SaleOrderTable = (props) => {
  const dispatch = useDispatch();
  const [orderDetail, setOrderDetail] = useState();
  const {instance, accounts} = useMsal();

  const columns = useMemo(
      () => [
        {
          accessorKey: 'orderNumber',
          header: 'Order Number',
          size: 0,
        },
        {
          accessorKey: 'employeeId',
          header: 'Employee Id',
          size: 0,
        },
        {
          accessorKey: 'site',
          header: 'Site',
          size: 0,
        },
        {
          accessorKey: 'qty',
          header: 'Quantity',
          size: 0,
        },
        {
          accessorKey: 'total',
          header: 'Total Price',
          accessorFn: (rowData) => {
            return (
              <span>
                {rowData?.total ? rowData?.total?.toLocaleString('en-US') : 0} ฿
              </span>
            );
          },
          size: 0,
        },
        {
          header: 'Order Status',
          accessorFn: (rowData) => {
            return (
              <span className={`badge rounded-pill ${orderStatusMappings?.find((osm) => osm?.key == rowData?.orderStatusId)?.badge}`}>
                {orderStatusMappings?.find((osm) => osm?.key == rowData?.orderStatusId)?.displayLabel?.en}
              </span>
            );
          },
          size: 0,
        },
        {
          header: 'Order Date',
          accessorFn: (rowData) => {
            return formatDateTime({
              dateTime: rowData?.createdAt,
            });
          },
        },
        {
          header: 'Action',
          accessorFn: (rowData) => {
            return <BsEye style={{cursor: 'pointer', fontSize: '20px'}}
              onClick={async () =>
                await callDetailApi({orderNumber: rowData?.orderNumber})} />;
          },
          size: 0,
        },
      ],
      [],
  );

  const [saleOrders, setSaleOrders] = useState();
  const [globalFilter, setGlobalFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState(props?.initialFilterStatus || null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const {data, isError, isFetching, isLoading} = useQuery({
    queryKey: [
      'table-data',
      globalFilter,
      pagination.pageIndex,
      pagination.pageSize,
      dateRange,
      statusFilter,
      orderDetail,
    ],

    queryFn: async () => {
      try {
        let payload = {
          offset: pagination.pageIndex * pagination.pageSize,
          limit: pagination.pageSize,
        };

        if (globalFilter) {
          payload = {
            ...payload,
            orderNumber: globalFilter,
          };
        }

        if (dateRange?.startDate) {
          payload = {
            ...payload,
            startDate: moment(dateRange?.startDate)
                .add(7, 'hours')
                .toISOString(),
          };
        }

        if (dateRange?.endDate) {
          payload = {
            ...payload,
            endDate: moment(dateRange?.endDate).add(7, 'hours').toISOString(),
          };
        }

        if (statusFilter) {
          payload = {
            ...payload,
            orderStatusId: statusFilter,
          };
        }

        const json = await apiPost({
          uri: `Admin/order/list`,
          payload: payload,
          msal: {
            instance: instance,
            accounts: accounts,
          },
        });

        setSaleOrders(json);

        return json;
      } catch (error) {
        dispatch(setFullLoading(true));
        console.error('queryFn error', error);
      }
    },
  });

  const handleDateRangeChange = (event) => {
    const {name, value} = event.target;
    setDateRange({
      ...dateRange,
      [name]: value,
    });
  };

  const callDetailApi = async ({orderNumber = ''} = {}) => {
    if (!orderNumber) return;

    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            const getOrderDetail = await apiGet({
              uri: `Admin/order/detail?orderNumber=${orderNumber}`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
            });

            setOrderDetail(getOrderDetail);

            dispatch(setFullLoading(false));
          } catch (error) {
            dispatch(setFullLoading(true));
            console.error(error);
          }
        })(),
    );
  };

  const syncOrders = ()=>{
    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            await apiPost({
              uri: `CronJobs/TriggerExportSaleOrder`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
              displayCustomError: true,
            });

            window.location.reload();
          } catch (error) {
            dispatch(setFullLoading(false));

            Swal.fire({
              title: 'Warning!',
              text: 'No data to sync!',
              icon: 'warning',
              confirmButtonText: 'OK',
            });
          }
        })(),
    );
  };

  return (
    <>
      {orderDetail ?
        (
          <SaleOrderDetail orderDetail={orderDetail} setOrderDetail={setOrderDetail} callDetailApi={callDetailApi} />
        ) : (
          <>

            {!props?.showTableOnly && (
              <Row className=' mb-4'>
                <Col className='col-12 mb-3 mt-3'>
                  <div className='custom-card'>

                    <Row className='px-4 py-3'>
                      <Col className="col-2">
                        <TextField
                          type="date"
                          label="Start Date"
                          name="startDate"
                          value={dateRange?.startDate || ''}
                          onChange={handleDateRangeChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100 date-filter-input"
                        />
                      </Col>
                      <Col className="col-2">
                        <TextField
                          type="date"
                          label="End Date"
                          name="endDate"
                          value={dateRange?.endDate || ''}
                          onChange={handleDateRangeChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className="w-100 date-filter-input"
                        />
                      </Col>
                      <Col className="col-2">
                        <select className="form-select admin-form-select" style={{lineHeight: '35px'}}
                          onChange={(e) => setStatusFilter(e.target.value)}>
                          <option value={''}>All Statuses</option>

                          {orderStatusMappings?.map((osm, i) => (
                            <option value={osm?.key} key={i}>{osm?.displayLabel?.en}</option>
                          ))}
                        </select>
                      </Col>

                      <Col className="col-2">
                      </Col>

                      {(!props?.hideExportBtn && props?.hideSyncBtn) && (
                        <Col className="col-2">
                        </Col>
                      )}

                      {!props?.hideExportBtn && (
                        <Col className="col-2">
                          <ExportSaleOrders data={saleOrders?.orders} />
                        </Col>
                      )}

                      {!props?.hideSyncBtn && (
                        <Col className="col-2">
                          <div className='admin-btn admin-btn-primary w-100 d-flex align-items-center justify-content-center' onClick={syncOrders}>
                            <GoSync size={'20px'} className='me-2' />Sync</div>
                        </Col>
                      )}
                    </Row>
                  </div>

                </Col>
              </Row>
            )}

            <Row>
              <Col className='col-12'>
                <MaterialReactTable
                  muiTablePaginationProps={{
                    rowsPerPageOptions: [
                      {label: '5', value: 5},
                      {label: '10', value: 10},
                      {label: 'all', value: data?.total || 0},
                    ],
                  }}
                  columns={columns}
                  data={saleOrders?.orders || []}
                  initialState={{showGlobalFilter: true}}
                  enableColumnFilters={false}
                  enableSorting={false}
                  manualFiltering
                  manualPagination
                  manualSorting
                  muiToolbarAlertBannerProps={
                  isError ?
                    {
                      color: 'error',
                      children: 'Error loading data',
                    } :
                    undefined
                  }
                  onGlobalFilterChange={setGlobalFilter}
                  onPaginationChange={setPagination}
                  muiSearchTextFieldProps={{
                    placeholder: 'Search Order Number',
                  }}
                  rowCount={saleOrders?.total || 0}
                  state={{
                    globalFilter,
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isFetching,
                  }}
                />
              </Col>
            </Row>
          </>
        )}
    </>
  );
};

const queryClient = new QueryClient(dataTableSettings);

const SaleOrder = (props) => (
  <QueryClientProvider client={queryClient}>
    <SaleOrderTable {...props}/>
  </QueryClientProvider>
);

export default SaleOrder;
