// page -> element -> language
// e.g home page -> hrMobile label -> language

export const language = {
  homePage: {
    hrMobile: {
      en: 'HR Mobile',
      th: 'หน้าหลัก',
    },
    OrderHistory: {
      en: 'Order History',
      th: 'ประวัติการสั่งซื้อ',
    },
    Addtocart: {
      en: 'Add to cart',
      th: 'เพิ่มลงตะกร้าสินค้า',
    },
    Filter: {
      Range: {
        en: 'Price',
        th: 'ช่วงราคา',
      },
      Size: {
        en: 'Size',
        th: 'ขนาด',
      },
      stock: {
        en: 'Stock',
        th: 'สต็อก',
      },
      availableStock: {
        en: 'Available Stock',
        th: 'มีสินค้าในสต็อก',
      },
      TextSize: {
        en: 'One Size',
        th: 'ขนาดเดียว',
      },
    },
    Cart: {
      cart: {
        en: 'Cart',
        th: 'ตะกร้าสินค้า',
      },
      CheckOut: {
        en: 'Check out',
        th: 'ชำระเงิน',
      },
      ConfirmPayment: {
        en: 'Confirm payment',
        th: 'ยืนยัน การชำระเงิน',
      },
      Ordertotal: {
        en: 'Order total',
        th: 'รวมสั่งชื้อ',
      },
      TransactionFee: {
        en: 'Transaction fee',
        th: 'ค่าธรรมเนียมการทำธุรกรรม',
      },
      NetTotal: {
        en: 'Net Total',
        th: 'รวมทั้งหมด',
      },
      PromptPay: {
        en: 'PromptPay',
        th: 'พร้อมเพย์',
      },
      MobileBanking: {
        en: 'Mobile Banking',
        th: 'การชำระเงินผ่านแอปพลิเคชันบนสมาร์ตโฟน',
      },
      QRPayment: {
        en: 'QR payment',
        th: 'ชำระเงินผ่านคิวอาร์โค้ด',
      },
      Expired: {
        en: 'QR payment expired!',
        th: 'คิวอาร์โค้ดหมดอายุ',
      },
      btnOK: {
        en: 'OK',
        th: 'ตกลง',
      },
      BtnDownload: {
        en: 'Download',
        th: 'ดาวน์โหลด',
      },
      TimeOut: {
        en: 'Timeout',
        th: 'หมดเวลา',
      },
      Minutes: {
        en: 'mins',
        th: 'นาที',
      },
      OutOfStock: {
        en: 'Out of stock!',
        th: 'สินค้าหมด',
      },
      Removeitem: {
        en: 'Remove item',
        th: 'ลบสินค้า',
      },
      Quantity: {
        en: 'Quantity',
        th: 'จำนวน',
      },
    },
    Warning: {
      en: 'Warning!',
      th: 'แจ้งเตือน!',
    },
    WarningRemove: {
      en: 'Are you sure to remove item?',
      th: 'คุณแน่ใจที่จะลบสินค้านี้ใช่ไหม!',
    },
    ProductOutOfStock: {
      en: 'This product is out of stock!',
      th: 'สินค้าชิ้นนี้หมด!',
    },
    selectSize: {
      en: 'Please select size!',
      th: 'กรุณาเลือกขนาด!',
    },
    btnOK: {
      en: 'OK',
      th: 'ตกลง',
    },
    btnCancel: {
      en: 'Cancel',
      th: 'ยกเลิก',
    },
    limitMessageFrist: {
      en: 'Your purchase reached to maximum limit [',
      th: 'การชื้อของคุณถึงขีดจำกัดสูงสุด [',
    },
    limitMessageLast: {
      en: ' items] for current month!',
      th: ' ชิ้น] สำหรับเดือนนี้',
    },
    LoadingMoreItems: {
      en: 'Loading more items..',
      th: 'แสดงสินค้าเพิ่มเติม..',
    },
    Norecords: {
      en: 'No records!',
      th: 'ไม่พบสินค้า',
    },
  },
  status: {
    en: 'Status',
    th: 'สถานะ',
  },
  payment: {
    en: 'Payment',
    th: 'จ่ายเงิน',
  },
  total: {
    en: 'Total',
    th: 'ทั้งหมด',
  },
  promptPay: {
    en: 'PromptPay',
    th: 'พร้อมเพย์',
  },
  banking: {
    en: 'Mobile Banking',
    th: 'ธนาคาร',
  },
  qrExpired: {
    en: 'QR is expired!',
    th: 'คิวอาร์หมดอายุ',
  },
  thankYou: {
    en: 'Thank you',
    th: 'ขอบคุณ',
  },
  clear: {
    en: 'Clear',
    th: 'ล้างตัวกรอง',
  },
  orderProbationWaring: {
    en: 'Eligible for only who already passed probation period!',
    th: 'คุณสามารถซื้อสินค้าราคาพิเศษได้เมื่อผ่านช่วงเวลาทดลองงานแล้วเท่านั้น',
  },
  termAndCondition: {
    text: {
      en: 'I agree to the',
      th: 'ฉันยอมรับ',
    },
    link: {
      en: 'terms & conditions.',
      th: 'ข้อกำหนดและเงื่อนไข',
    },
  },
  note: {
    en: 'Note',
    th: 'หมายเหตุ',
  },
  ok: {
    en: 'OK',
    th: 'ตกลง',
  },
  pay: {
    en: 'Pay',
    th: 'จ่าย',
  },
  Timeout: {
    en: 'Timeout',
    th: 'หมดเวลา',
  },
  minutes: {
    en: 'minutes',
    th: 'นาที',
  },
  seconds: {
    en: 'seconds',
    th: 'วินาที',
  },
  order_N: {
    en: 'Order',
    th: 'การสั่งซื้อ',
  },
  paymentFailed: {
    en: 'Payment failed',
    th: 'ชำระเงินไม่สำเร็จ',
  },
  warning: {
    en: 'Warning!',
    th: 'แจ้งเตือน!',
  },
  postPaymentSuccessMsg: {
    en: `You successfully placed orders. Please collect this order number for reference.`,
    th: `ระบบได้รับคำสั่งซื้อของท่านแล้ว กรุณาจดเลขคำสั่งซื้อนี้ไว้`,
  },
  postPaymentFailMsg: {
    en: `Your payment has been terminated.`,
    th: `คำสั่งซื้อของคุณถูกยกเลิก`,
  },
  somethingWentWrong: {
    en: `Something went wrong!`,
    th: `ไม่สามารถทำรายการได้`,
  },
  checkoutItemsNotAvailable: {
    en: `Some items are not available. Please review the cart again!`,
    th: `สินค้าบางรายการของคุณหมดแล้ว กรุณาตรวจสอบตะกร้าของคุณอีกครั้ง`,
  },
  noRecords: {
    en: 'No records.',
    th: 'ไม่พบข้อมูล',
  },
  maintenanceMsg: {
    firstLine: {
      en: `We'll be back very soon..`,
      th: `รอหน่อยนะ..`,
    },
    secondLine: {
      en: `Preparing products for your next exciting experience!!`,
      th: `เรากำลังเตรียมสินค้าที่น่าสนใจสำหรับวันพรุ่งนี้!!`,
    },
  },
  cancel: {
    en: 'Cancel',
    th: 'ยกเลิก',
  },
  payAgainMsg: {
    en: 'Payment failed! Please pay again!',
    th: 'ชำระเงินไม่สำเร็จ กรุณาลองใหม่อีหครั้ง',
  },
};
