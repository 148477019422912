import moment from 'moment';

export const formatDateTime = ({
  dateTime = null,
  format = 'DD MMM YYYY',
  dateOnly = false,
  convertToThai = true,
} = {}) => {
  if (!dateTime) return;

  if (!dateOnly) {
    format = format + ' hh:mm A';
  }

  const localThaiTime = moment.utc(dateTime).local();

  if (!convertToThai) {
    return localThaiTime.format(format);
  }

  // date time in Thai
  const day = localThaiTime.format('DD');
  const month = localThaiTime.format('MM');
  const year = localThaiTime.add(543, 'years').format('YYYY');
  const time = localThaiTime.format('HH:mm:ss') + ' น.';
  let dateTimeInThai = '';
  let mm = '';

  switch (month) {
    case '01':
      mm = 'ม.ค.';
      break;
    case '02':
      mm = 'ก.พ.';
      break;
    case '03':
      mm = 'มี.ค.';
      break;
    case '04':
      mm = 'เม.ย.';
      break;
    case '05':
      mm = 'พ.ค.';
      break;
    case '06':
      mm = 'มิ.ย.';
      break;
    case '07':
      mm = 'ก.ค.';
      break;
    case '08':
      mm = 'ส.ค.';
      break;
    case '09':
      mm = 'ก.ย.';
      break;
    case '10':
      mm = 'ต.ค.';
      break;
    case '11':
      mm = 'พ.ย.';
      break;
    case '12':
      mm = 'ธ.ค.';
      break;
    default:
      mm = 'ม.ค.';
      break;
  }
  dateTimeInThai = day + ' ' + mm + ' ' + year;

  if (!dateOnly) {
    dateTimeInThai = dateTimeInThai + ' ' + time;
  }

  return dateTimeInThai;
};
