import {Col, Row} from 'react-bootstrap';
import {ProductLazyLoadImg} from './ProductImg';
import Lottie from 'lottie-react';
import dataNotFound from '../../assets/images/not-found-item.json';
import {language} from '../../language';
import {useSelector} from 'react-redux';

export const CartBtn = (props)=>{
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);

  return (
    <>
      {props?.products?.length > 0 && (
        <>
          {!(props?.promiseInProgress || props?.isFullLoading) && (
            <>
              {props?.showCartBtnLoading ? (
                    <div className='inline-btn-loader bg-dark mt-2 float-box-shadow to-sake-btn pe-none animated-hover-btn'>
                      <Row>
                        <Col className='col-12 p-0'>
                          <span className="loader"></span>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <>
                      {props?.cartItems?.length > 0 ? (
                        <div data-cy="cart-btn" className={`px-5 py-2 add-to-cart-btn to-sake-btn animated-hover-btn mt-2 float-box-shadow ${props?.cartItems?.length ? 'bg-dark' : 'bg-gray-disabled disabled-cursor'}`} onClick={() => {
                          if (!props?.cartItems?.length) return;
                          props?.setShowCartPage(true);
                        }}>
                          <Row>
                            <Col className='col-6 p-0'>
                              <div className='img-inside-cart-btn-wrapper'>
                                {props?.cartItems?.slice(0, 4)?.map((v, i) => (
                                  <ProductLazyLoadImg key={i} className="img-inside-cart-btn" imgId={v?.image} effect='none'/>
                                ))}
                              </div>

                            </Col>
                            <Col className='col-6 p-0'>
                              <h4 className='text-white my-2' style={{float: 'right', textWrap: 'nowrap'}}>{_.sumBy(props?.cartItems, 'subTotalPerItem')?.toLocaleString('en-US') || 0} ฿</h4>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <div className='px-5 py-2 add-to-cart-btn to-sake-btn animated-hover-btn mt-2 float-box-shadow bg-dark text-white'>
                          <Row className='d-flex align-items-center'>
                            <Col className='col-6 p-0 '>
                              <h4 className='text-white my-2'>{language?.homePage?.Cart?.cart?.[hrMobileLanguage]}</h4>
                            </Col>
                            <Col className='col-6 p-0'>
                              <h4 className='text-white my-2' style={{float: 'right', textWrap: 'nowrap'}}>0 ฿</h4>
                            </Col>
                          </Row>
                        </div>
                      )}
                    </>
                  )}
            </>
          )}
        </>
      )}

      {(!props?.showProductSkeletonState && props?.products?.length == 0) && (
        <>
          <Lottie animationData={dataNotFound} speed={10} loop={true} style={{width: '40%', margin: '5rem auto 0 auto'}} />
          <p className='text-center font-weight-bold'>{language?.homePage?.Norecords?.[hrMobileLanguage]}</p>
        </>
      )}
    </>
  );
};
