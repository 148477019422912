import {useMsal} from '@azure/msal-react';
import sls from 'react-secure-storage';

export const AzureLogin = () => {
  sls.clear();
  localStorage.clear();

  const {instance} = useMsal();
  instance
      .loginRedirect({
        scopes: [process.env.REACT_APP_API_SCOPE1],
      })
      .catch((err) => {
        // console.error('MSAL login error', err);
      });

  return <></>;
};

