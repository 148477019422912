import {Col, Row} from 'react-bootstrap';
import {useEffect, useMemo, useState} from 'react';
import {MdArrowBackIosNew} from 'react-icons/md';
import {QueryClient, QueryClientProvider, useQuery} from '@tanstack/react-query';
import {useDispatch} from 'react-redux';
import {useMsal} from '@azure/msal-react';
import {setFullLoading} from '../../store/appSlice';
import {apiGet} from '../../apis';
import MaterialReactTable from 'material-react-table';
import {dataTableSettings} from '../../helpers/data-table-settings';
import {BsEye} from 'react-icons/bs';
import SyncLogDetail from './SyncLogDetail';
import {syncLogTypeMappings} from '../../types/sync-log';
import {formatDateTime} from '../../helpers/datetime';

const SyncLogListTable = (props) =>{
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const dispatch = useDispatch();
  const {instance, accounts} = useMsal();
  const [syncLogListData, setSyncLogListData] = useState();
  const [syncIdForApi, setSyncIdForApi] = useState();

  const columns = useMemo(
      () => [
        {
          header: 'Success',
          size: 0,
          accessorFn: (row)=>{
            return <span className='text-success'>{row?.successTotal}</span>;
          },
        },
        {
          header: 'Fail',
          size: 0,
          accessorFn: (row)=>{
            return <span className='text-danger'>{row?.failTotal}</span>;
          },
        },
        {
          header: 'Total',
          size: 0,
          accessorFn: (row)=>{
            return <span className='text-primary'>{row?.successTotal + row?.failTotal}</span>;
          },
        },
        {
          header: 'Synced Date',
          size: 0,
          accessorFn: (rowData) => {
            return formatDateTime({
              dateTime: rowData?.createdAt,
            });
          },
        },
        {
          header: 'Action',
          accessorFn: (rowData) => {
            return <BsEye style={{cursor: 'pointer', fontSize: '20px'}}
              onClick={() => setSyncIdForApi(rowData.syncId)} />;
          },
        },
      ],
      [],
  );

  const {isError, isFetching, isLoading} = useQuery({
    queryKey: [
      'table-data',
    ],

    queryFn: async () => {
      try {
        dispatch(setFullLoading(true));

        const json = await apiGet({
          uri: `Admin/logs/product/lists?logsType=${props?.syncLogType}`,
          msal: {
            instance: instance,
            accounts: accounts,
          },
        });

        setSyncLogListData(json);
        dispatch(setFullLoading(false));

        return json;
      } catch (error) {
        dispatch(setFullLoading(true));
        console.error('queryFn error', error);
      }
    },
  });

  return (
    <>

      {syncIdForApi ? (
        <SyncLogDetail syncIdForApi={syncIdForApi} setSyncIdForApi={setSyncIdForApi}/>
        ) : (
          <Row className='sync-log-detail'>
            <Col xs={12}>
              <div className='mb-4 pointer d-flex align-items-center' onClick={() => props?.setSyncLogType(null)}>
                <MdArrowBackIosNew size={'20px'} />
                <span className='ms-2'>Sync Log Types</span>
              </div>

              <div className='custom-card px-4 py-4 mb-5 mt-2'>
                {syncLogTypeMappings?.find((v) => v.key == props?.syncLogType)?.displayLabel} Synced Log List
              </div>

              <MaterialReactTable
                enablePagination={false}
                muiTablePaginationProps={{
                  rowsPerPageOptions: [
                    {label: '5', value: 5},
                    {label: '10', value: 10},
                    {label: 'all', value: syncLogListData?.length || 0},
                  ],
                }}
                enableGlobalFilter={false}
                columns={columns}
                data={syncLogListData || []}
                enableColumnFilters={false}
                enableSorting={false}
                manualFiltering
                manualPagination
                manualSorting
                muiToolbarAlertBannerProps={
                            isError ?
                              {
                                color: 'error',
                                children: 'Error loading data',
                              } :
                              undefined
                }
                rowCount={syncLogListData?.total || 0}
                state={{
                  isLoading,
                  showAlertBanner: isError,
                  showProgressBars: isFetching,
                }}
              />
            </Col>
          </Row>
        )
      }

    </>

  );
};

const queryClient = new QueryClient(dataTableSettings);

const SyncLogList = (props) => (
  <QueryClientProvider client={queryClient}>
    <SyncLogListTable {...props}/>
  </QueryClientProvider>
);

export default SyncLogList;
