import {Col, Row} from 'react-bootstrap';
import {RxCross1} from 'react-icons/rx';
import '../../styles/user/main.scss';
import '../../styles/user/order-history-detail.scss';
import {orderStatus, orderStatusMappings, paymentMethod, rePayOrderStatuses} from '../../types/order';
import {useEffect, useState} from 'react';
import {ProductLazyLoadImg} from '../../components/user/ProductImg';
import {language} from '../../language';
import {useSelector} from 'react-redux';
import {apiPost} from '../../apis';
import {useMsal} from '@azure/msal-react';
import moment from 'moment';
import {useNavigate} from 'react-router-dom';
import {PaymentOptions} from '../../components/user/PaymentOptions';
import Swal from 'sweetalert2';
import {paymentOptionFail} from '../../helpers/swal/cart';
import {bankingOptionMappings} from '../../types/payment';

const OrderHistoryDetail = (props) => {
  const [status, setStatus] = useState();
  const hrMobileLanguage = useSelector((state) => state.app.slsHrMobileLanguage);
  const {instance, accounts} = useMsal();
  const [showCartBtnLoading, setShowCartBtnLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setStatus(props?.orderHistory?.orderStatus);
  }, [props?.orderHistory?.orderStatus]);

  const [isRePay, setIsRePay] = useState(false);
  const [isQrRePay, setIsQrRePay] = useState(false);
  const [isBankingRePay, setIsBankingRePay] = useState(false);
  const [isConfirmRePay, setIsConfirmRePay] = useState(true);
  const [showBankingOptions, setShowBankingOptions] = useState(false);
  const [bankOptionName, setBankOptionName] = useState('');

  // reset payment option on switch option
  useEffect(() => {
    setIsConfirmRePay(false);

    if (showBankingOptions && bankOptionName) {
      setIsConfirmRePay(true);
    }

    if (!showBankingOptions) {
      setIsConfirmRePay(true);
    }
  }, [showBankingOptions, bankOptionName]);

  useEffect(() => {
    setIsRePay(false);

    if (isQrRePay || isBankingRePay) {
      setIsRePay(true);
    }
  }, [isQrRePay, isBankingRePay]);

  useEffect(() => {
    (async () => {
      try {
        if (!props?.orderHistory) return;

        const orderedDate = moment(props?.orderHistory?.createdAt).format('DD MMM YYYY');
        const todayDate = moment().format('DD MMM YYYY');
        let canRePay = false;
        setIsQrRePay(false);
        setIsBankingRePay(false);

        if (orderedDate == todayDate) {
          canRePay = rePayOrderStatuses?.some((rp) => rp == props?.orderHistory?.orderStatus?.orderStatusId);
        }

        if (!canRePay) return;

        if (props?.orderHistory?.chargesPayment?.qrPromtpay) {
          setIsQrRePay(true);
        } else {
          setIsQrRePay(false);
        }

        if (props?.orderHistory?.chargesPayment?.authorizeUri) {
          setIsBankingRePay(true);
        } else {
          setIsBankingRePay(false);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [props?.orderHistory]);

  const rePay = async () =>{
    try {
      setShowCartBtnLoading(true);

      if (!props?.orderHistory?.orderNumber) return;

      if (isQrRePay || isBankingRePay) {
        const orderRes = await apiPost({
          uri: `Order/repayment`,
          payload: {
            'orderNumber': props?.orderHistory?.orderNumber,
            'paymentMethod': showBankingOptions ? paymentMethod?.banking : paymentMethod?.promptPay,
            'bankName': bankOptionName,
          },
          msal: {
            instance: instance,
            accounts: accounts,
          },
        });

        if (showBankingOptions) {
          if (orderRes?.chargesPayment?.authorizeUri) {
            window.location.href = orderRes?.chargesPayment?.authorizeUri;
          }

          setTimeout(() => {
            Swal.fire(paymentOptionFail).then((res) =>{
              if (res?.isConfirmed) {
                navigate(`/order-histories?orderNumber=${orderRes?.orderNumber}`);
                return;
              }

              window.location.href = process.env.PUBLIC_URL;
            });
          }, 1000 * 5);

          return;
        } else {
          navigate(`/order/qr-payment?qrUrl=${orderRes?.chargesPayment?.qrPromtpay}&startedDate=${orderRes?.chargesPayment?.created_at}&orderNumber=${orderRes?.orderNumber}`);
          return;
        }
      }

      setShowCartBtnLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="order-detail">
      <Row>
        <Col className='col-6'>
          <span style={{cursor: 'pointer'}} onClick={() => props?.setShowHistories(true)}>
            <RxCross1 style={{fontSize: '26px'}} />
          </span>
        </Col>
        <Col className='col-6' >
        </Col>
      </Row>

      <h3 className='my-4' >{language?.order_N?.[hrMobileLanguage]} {props?.orderHistory?.orderNumber}</h3>

      <div className='container my-3'>
        <Row className="order-detail-list">
          {props?.orderHistory?.orderDetails?.map((v, i) => (
            <Col className='col-12 mb-4' key={i}>
              <div className="order-detail-item">
                <Row>
                  <Col className='col-4'>
                    <ProductLazyLoadImg className="item-img" imgId={v?.image}/>
                  </Col>

                  <Col className='col-8'>
                    <p className='d-inline-block text-truncate mb-1 text-dark fw-bold' style={{maxWidth: '100%'}}>{v?.productName}</p>

                    <Row className='d-flex align-items-center justify-content-center text-secondary'>
                      <Col className='col-8 pe-0'>
                        <div>
                          <span className='mx-2' style={{fontSize: '14px'}}>{language?.homePage?.Cart?.Quantity?.[hrMobileLanguage]}:
                            <span className='text-dark fw-bold ms-2'>
                              {v?.qty || 0}
                            </span>
                          </span>
                        </div>
                        <div>
                          <span className='mx-2' style={{fontSize: '14px'}}>{language?.homePage?.Filter?.Size?.[hrMobileLanguage]}:
                            <span className='text-dark fw-bold ms-2'>
                              {v?.size || 'N/A'}
                            </span>
                          </span>
                        </div>
                      </Col>
                      <Col className='col-4 ps-0'>
                        <span className='text-dark fw-bold'>{v?.price?.toLocaleString('en-US') || 0} ฿</span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>
          ))}
        </Row>

        <ul className="list-group list-group-flush mt-5">
          <li className="list-group-item text-secondary">{language?.status?.[hrMobileLanguage]}: <span style={{float: 'right', color: orderStatusMappings?.find((osm) =>
            osm?.key == status?.orderStatusId)?.color,
          }}>
            <span style={{float: 'right'}}>{orderStatusMappings?.find((osm) => osm?.key == status?.orderStatusId)?.displayLabel?.[hrMobileLanguage]}</span>

            {(status?.orderStatusId == orderStatus.cancelled || status?.orderStatusId == orderStatus.pending) && (
              <>
                <br />
                <small className='text-secondary' style={{float: 'right'}}>
                  {language?.note?.[hrMobileLanguage]}: {props?.orderHistory?.orderStatus?.orderHistories?.find((oh) => oh?.orderStatusId == status?.orderStatusId)?.note || ''}
                </small>
              </>
            )}
          </span></li>
          <li className="list-group-item text-secondary">{language?.payment?.[hrMobileLanguage]}: <span className='' style={{float: 'right'}}>
            {props?.orderHistory?.chargesPayment?.qrPromtpay ?
              language?.promptPay?.[hrMobileLanguage] :
              (bankingOptionMappings?.find((bom) => bom?.key == props?.orderHistory?.paymentMethod?.bankName)?.displayLabel?.[hrMobileLanguage] || language?.banking?.[hrMobileLanguage])
            }
          </span></li>
          <li className="list-group-item text-secondary">{language?.total?.[hrMobileLanguage]}: <span className='text-dark' style={{float: 'right'}}>
            <span className='text-dark fw-bold'>
              {props?.orderHistory?.total?.toLocaleString('en-US') || 0} ฿
            </span>
          </span></li>
        </ul>

        {showCartBtnLoading ? (
          <div className='inline-btn-loader bg-red mt-2 float-box-shadow to-sake-btn pe-none animated-hover-btn'>
            <Row>
              <Col className='col-12 p-0'>
                <span className="loader"></span>
              </Col>
            </Row>
          </div>
        ) : (
          <>
            {isRePay ? (
              <>
                <div className='mt-4'>
                  <PaymentOptions
                    showBankingOptions={showBankingOptions}
                    setShowBankingOptions={setShowBankingOptions}
                    bankOptionName={bankOptionName}
                    setBankOptionName={setBankOptionName}
                  />
                </div>

                <div className={`px-5 py-2 order-detail-btn mt-4 ${isConfirmRePay ? 'bg-red' : 'bg-gray-disabled'} float-box-shadow animated-hover-btn`}
                  onClick={async () => {
                    if (!isConfirmRePay) return;

                    if (isRePay && isConfirmRePay) {
                      await rePay();
                      return;
                    }
                  }}>
                  <Row>
                    <Col className='col-12 p-0'>
                      <h3 className='text-white my-2 text-center' style={{whiteSpace: 'nowrap'}}>
                        {language?.pay?.[hrMobileLanguage]}
                      </h3>
                    </Col>
                  </Row>
                </div>
              </>
            ) : (
              <div className={`px-5 py-2 order-detail-btn mt-4 bg-black float-box-shadow animated-hover-btn`}
                onClick={async () => props?.setShowHistories(true)}>
                <Row>
                  <Col className='col-12 p-0'>
                    <h3 className='text-white my-2 text-center' style={{whiteSpace: 'nowrap'}}>
                      {language?.ok?.[hrMobileLanguage]}
                    </h3>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderHistoryDetail;
