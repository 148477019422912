// import {useMemo, useState} from 'react';
import {setFullLoading} from '../../store/appSlice';
import {useDispatch} from 'react-redux';
import {Col, Row} from 'react-bootstrap';
import {useEffect, useState} from 'react';
import {DashboardLineGraph} from '../../components/back-office/DashboardLineGraph';
import {LuShoppingCart} from 'react-icons/lu';
import '../../styles/back-office/dashboard.scss';
import {orderStatusMappings} from '../../types/order';
import SaleOrder from './SaleOrder';
import {useMsal} from '@azure/msal-react';
import {trackPromise} from 'react-promise-tracker';
import {apiGet} from '../../apis';
import moment from 'moment';
import {formatDateTime} from '../../helpers/datetime';

const Dashboard = () => {
  const dispatch = useDispatch();
  const {instance, accounts} = useMsal();
  const [filterDate, setFilterDate] = useState('Year');
  const [statusSummaries, getStatusSummaries] = useState();
  const [graphSummaries, setGraphSummaries] = useState();
  const [displayDateLabel, setDisplayDateLabel] = useState();

  const callGraphSummaryApi = async (dateRange) =>{
    let filterType = 'days';

    if (filterDate == 'Year') {
      filterType = 'month';
    }

    const getGraph = await apiGet({
      uri: `Admin/orders/site?startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}&filterType=${filterType}`,
      msal: {
        instance: instance,
        accounts: accounts,
      },
    });

    setGraphSummaries(getGraph);
  };

  const callStatusSummaryApi = async (dateRange) =>{
    const getStatusSummary = await apiGet({
      uri: `Admin/order/statussummary?startDate=${dateRange?.startDate}&endDate=${dateRange?.endDate}`,
      msal: {
        instance: instance,
        accounts: accounts,
      },
    });

    getStatusSummaries(getStatusSummary);
  };

  useEffect(() => {
    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));
            const dateRange = getDateRange();

            setDisplayDateLabel(`${formatDateTime({dateTime: dateRange?.startDate, dateOnly: true})} - ${formatDateTime({dateTime: dateRange?.endDate, dateOnly: true})}`);

            await callGraphSummaryApi(dateRange);
            await callStatusSummaryApi(dateRange);

            dispatch(setFullLoading(false));
          } catch (error) {
            dispatch(setFullLoading(true));
            console.error(error);
          }
        })(),
    );
  }, [filterDate, dispatch]);

  const getDateRange = ()=>{
    let dateRange = {
      startDate: moment().subtract(1, 'year'),
      endDate: moment(),
    };

    switch (filterDate) {
      case 'Year':
        dateRange = {
          startDate: moment().subtract(1, 'year'),
          endDate: moment(),
        };
        break;
      case '30 Days':
        dateRange = {
          startDate: moment().subtract(30, 'days'),
          endDate: moment(),
        };
        break;
      case '7 Days':
        dateRange = {
          startDate: moment().subtract(7, 'days'),
          endDate: moment(),
        };
        break;
    }

    const dateFormat = 'YYYY-MM-DD';
    dateRange = {
      startDate: dateRange.startDate.format(dateFormat) + ' 00:00:00',
      endDate: dateRange.endDate.format(dateFormat) + ' 00:00:00',
    };

    return dateRange;
  };

  return (
    <>
      <Row>
        <Col xs={6}>
          <p><span className='font-weight-bold'>Date: </span>{displayDateLabel}</p>
        </Col>
        <Col xs={6}>
          <div className="btn-group float-right" role="group" aria-label="Basic radio toggle button group">
            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" />
            <label className={`btn btn-outline-primary admin-btn-group-primary ${filterDate == 'Year' && 'checked'}`} htmlFor="btnradio1"
              onClick={() => setFilterDate('Year')}
            >Year</label>

            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" />
            <label className={`btn btn-outline-primary admin-btn-group-primary ${filterDate == '30 Days' && 'checked'}`} htmlFor="btnradio2"
              onClick={() => setFilterDate('30 Days')}
            >30 Days</label>

            <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" />
            <label className={`btn btn-outline-primary admin-btn-group-primary ${filterDate == '7 Days' && 'checked'}`} htmlFor="btnradio3"
              onClick={() => setFilterDate('7 Days')}
            >7 Days</label>
          </div>
        </Col>
      </Row>

      <Row className='mt-4 mb-5'>
        <Col xs={8}>
          <div className='custom-card p-4' style={{height: '604px'}}>
            <DashboardLineGraph graphSummaries={graphSummaries}/>
          </div>
        </Col>
        <Col xs={4}>
          <div className='custom-card summary-order-status-container p-4 py-5'>

            {statusSummaries?.map((v, i) => (
              <div key={i} style={{marginTop: i != 0 ? '38px' : '0'}}>
                <span className={`summary-status-icon-bg me-2 ${orderStatusMappings?.find((osm) => osm?.key == v?.id)?.summaryClass}`}>
                  <LuShoppingCart className={`summary-status-icon-inner ${orderStatusMappings?.find((osm) => osm?.key == v?.id)?.summaryClass}`}/>
                </span>

                <span>{orderStatusMappings?.find((osm) => osm?.key == v?.id)?.displayLabel?.en}</span>

                <span className='float-right' style={{color: orderStatusMappings?.find((osm) => osm?.key == v?.id)?.color}}>{v?.total || 0}</span>
              </div>
            ))}
          </div>
        </Col>
      </Row>
      <hr />
      <br />

      <SaleOrder showTableOnly={true}/>
    </>
  );
};

export default Dashboard;
