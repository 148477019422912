import Axios from 'axios';
import {callMsal} from './msal';
import {logout} from '../helpers/logout';

const apiEndpoint = 'https://graph.microsoft.com/v1.0/';
const getCallingMaps = new Map();

export const apiAzureGet = async ({
  uri = '',
  msal,
}) => {
  try {
    const msalCall = await callMsal({
      msal,
      scopes: ['User.Read', 'profile'],
    });

    const url = `${apiEndpoint}${uri}`;

    let isThisAPICalling = getCallingMaps.get(url);

    if (!isThisAPICalling) {
      isThisAPICalling = Axios.get(url, {
        headers: {
          Authorization: `Bearer ${msalCall?.accessToken}`,
        },
      });
      getCallingMaps.set(url, isThisAPICalling);
    }

    return isThisAPICalling.then((res) => {
      getCallingMaps.set(url, null);

      return res?.data || undefined;
    }).catch((err) => {
      console.log(`apiAzureGet error`, err);

      if (err?.response?.status == 401) {
        return logout({msal});
      }
    });
  } catch (error) {}
};
