import {orderStatus, orderStatusMappings} from '../order';

const saleOrderDropdownMappings = [
  {
    currentStatus: orderStatus?.toPay,
    allowedStatuses: [orderStatus?.paid],
  },
  {
    currentStatus: orderStatus?.paid,
    allowedStatuses: [orderStatus?.pickupReady],
  },
  {
    currentStatus: orderStatus?.pickupReady,
    allowedStatuses: [orderStatus?.closed, orderStatus?.cancelled],
  },
  {
    currentStatus: orderStatus?.pending,
    allowedStatuses: [orderStatus?.pending, orderStatus?.pickupReady],
  },
];

export const saleOrderDropdownLabelMappings = saleOrderDropdownMappings?.map((sodm) => {
  return {
    ...sodm,
    allowedStatuses: sodm?.allowedStatuses?.map((as) =>{
      return orderStatusMappings?.find((osm) => osm?.key == as);
    })?.filter((v) => v),
  };
});
