export const bankingOptions = {
  bbl: 'mobile_banking_bbl',
  kbank: 'mobile_banking_kbank',
  bay: 'mobile_banking_bay',
  ktb: 'mobile_banking_ktb',
  scb: 'mobile_banking_scb',
};

export const bankingOptionMappings = [
  {
    key: bankingOptions.bbl,
    displayLabel: {
      en: `Bangkok Bank`,
      th: `ธนาคารกรุงเทพ`,
    },
  },
  {
    key: bankingOptions.kbank,
    displayLabel: {
      en: `Kasikorn Bank`,
      th: `ธนาคารกสิกรไทย`,
    },
  },
  {
    key: bankingOptions.bay,
    displayLabel: {
      en: `Bank of Ayudhya`,
      th: `ธนาคารกรุงศรีอยุธยา`,
    }},
  {
    key: bankingOptions.ktb,
    displayLabel: {
      en: `Krungthai Bank`,
      th: `ธนาคารกรุงไทย`,
    },
  },
  {
    key: bankingOptions.scb,
    displayLabel: {
      en: `Siam Commercial Bank`,
      th: `ธนาคารไทยพาณิชย์`,
    },
  },
];
