import {Col, Row} from 'react-bootstrap';
import {useState} from 'react';
import '../../styles/back-office/sync-logs.scss';
import SyncLogList from '../../components/back-office/SyncLogList';
import {syncLogTypes} from '../../types/sync-log';
import {LuBox, LuShoppingCart} from 'react-icons/lu';
import {BsCashCoin} from 'react-icons/bs';

const SyncLog = () => {
  const [syncLogType, setSyncLogType] = useState();

  return (
    <>
      {syncLogType ? (
        <SyncLogList syncLogType={syncLogType} setSyncLogType={setSyncLogType} />
      ) : (
        <Row className='sync-logs'>
          <Col xs={3}>
            <div className='custom-card log-menu-item text-center d-flex align-items-center justify-content-center'
              onClick={() => setSyncLogType(syncLogTypes.product)}>
              <LuBox size={'24px'} className='me-2' />
              Products
            </div>
          </Col>
          <Col xs={3}>
            <div className='custom-card log-menu-item text-center d-flex align-items-center justify-content-center'
              onClick={() => setSyncLogType(syncLogTypes.stock)}>
              <LuShoppingCart size={'24px'} className='me-2' />
              Stocks
            </div>
          </Col>
          <Col xs={3}>
            <div className='custom-card log-menu-item text-center d-flex align-items-center justify-content-center'
              onClick={() => setSyncLogType(syncLogTypes.price)}>
              <BsCashCoin size={'24px'} className='me-2' />
              Prices
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SyncLog;
