import {Col, Row} from 'react-bootstrap';
import LeftSidebar from '../components/back-office/left-sidebar';
import NavBar from '../components/back-office/navbar';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Dashboard from '../pages/back-office/Dashboard';
import Product from '../pages/back-office/Product';
import SaleOrder from '../pages/back-office/SaleOrder';
import Pop from '../pages/back-office/Pop';
import Setting from '../pages/back-office/Setting';
import {useDispatch, useSelector} from 'react-redux';
import {trackPromise, usePromiseTracker} from 'react-promise-tracker';
import {useEffect, useState} from 'react';
import {useIsAuthenticated, useMsal} from '@azure/msal-react';
import sls from 'react-secure-storage';
import {generateEncryptedData} from '../helpers/generateEncryptedData';
import SyncLog from '../pages/back-office/SyncLog';
import {InteractionStatus} from '@azure/msal-browser';

import '../styles/back-office/main.scss';
import {apiAzureGet} from '../apis/azure';
import {setFullLoading} from '../store/appSlice';
import {setAuth} from '../store/slices/authSlice';
import {AzureLogin} from '../components/AzureLogin';
import POPLeftSidebar from '../components/back-office/pop-left-sidebar';
import {orderStatus} from '../types/order';

const AzureLogout = () => {
  sls.clear();
  localStorage.clear();

  const {instance} = useMsal();
  instance.logoutRedirect({postLogoutRedirectUri: process.env.PUBLIC_URL + '/back-office'}).catch((err) => {
    // console.log('MASL logout error', err);
  });

  return <></>;
};

export const BackOfficeRoutes = () =>{
  const isFullLoading = useSelector((state) => state.app.isFullLoading);
  const {promiseInProgress} = usePromiseTracker();
  const [isBarOpen, setIsBarOpen] = useState(localStorage.getItem('isBarOpen') == 'true' ? true : false);
  const isAzureAuthenticated = useIsAuthenticated();
  const {instance, accounts, inProgress} = useMsal();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.auth);

  useEffect(() => {
    localStorage.setItem('isBarOpen', isBarOpen);
  }, [promiseInProgress, isFullLoading, isBarOpen]);

  useEffect(() => {
    generateEncryptedData();
  }, []);

  // middleware logic here
  useEffect(() => {
    if (inProgress == InteractionStatus.None && !isAzureAuthenticated) return;

    trackPromise(
        (async () => {
          try {
            dispatch(setFullLoading(true));

            let aadProfile = await apiAzureGet({
              uri: `users/${accounts[0]?.username}`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
            });

            const graphSecGroup = await apiAzureGet({
              uri: `me/transitiveMemberOf/microsoft.graph.group?$top=999`,
              msal: {
                instance: instance,
                accounts: accounts,
              },
            });

            let isAdmin = graphSecGroup?.value?.some((v) => v?.id == process.env.REACT_APP_ADMIN_SEC_GROUP) || false;
            let isPopRole = graphSecGroup?.value?.some((v) => v?.id == process.env.REACT_APP_POP_SEC_GROUP) || false;

            if (isAdmin && isPopRole) {
              isAdmin = true;
              isPopRole = false;
            }

            aadProfile = {
              ...aadProfile,
              isAdmin: isAdmin,
              isPopRole: isPopRole,
            };

            // START: to mock roles
            if (process.env.REACT_APP_ENV == 'local') {
              aadProfile = {
                ...aadProfile,
                isAdmin: false,
                isPopRole: true,
              };
            }
            // END: to mock roles

            aadProfile = {
              ...aadProfile,
              isBackOfficeUser: aadProfile.isAdmin || aadProfile.isPopRole,
            };

            console.log('aadProfile', aadProfile);

            dispatch(setAuth(aadProfile));
            dispatch(setFullLoading(false));
          } catch (error) {
            console.error(error);
          } finally {
            dispatch(setFullLoading(false));
          }
        })(),
    );
  }, [inProgress, isAzureAuthenticated, instance, accounts, dispatch]);

  useEffect(() => {
    if (!authUser) return;

    dispatch(setFullLoading(true));

    if (authUser?.isAdmin) {
      if (window.location.pathname.includes('pop-admin')) {
        return window.location.href = process.env.PUBLIC_URL + `/back-office`;
      }
    }

    if (authUser?.isPopRole) {
      if (!window.location.pathname.includes('/back-office/pop-admin/')) {
        return window.location.href = process.env.PUBLIC_URL + `/back-office/pop-admin/pickup-orders`;
      }
    }

    dispatch(setFullLoading(false));
  }, [authUser, dispatch]);

  // To inspect only
  if (authUser?.isAdmin && authUser?.isPopRole) {
    return (
      <h3>Detected multiple roles!</h3>
    );
  }

  if (inProgress == InteractionStatus.None && !isAzureAuthenticated) {
    return <AzureLogin />;
  }

  return (
    <>
      {authUser?.isBackOfficeUser && (
        <>
          {authUser?.isPopRole && (
            <div className='back-office-app pop-container'>
              <Row>
                <POPLeftSidebar isBarOpen={isBarOpen}/>

                <Col className={`right-content ${isBarOpen ? 'col-10' : 'col-12'}`}>
                  <NavBar isBarOpen={isBarOpen} setIsBarOpen={setIsBarOpen}/>

                  <BrowserRouter basename={process.env.PUBLIC_URL}>
                    <Routes>
                      <Route
                        path="/back-office/pop-admin/pickup-orders"
                        element={<Pop />}
                      />
                      <Route
                        path="/back-office/pop-admin/pending-orders"
                        element={<SaleOrder initialFilterStatus={orderStatus?.pending} showTableOnly={true} />}
                      />
                      <Route
                        path="/back-office/pop-admin/sale-order"
                        element={<SaleOrder hideExportBtn={false} hideSyncBtn={true}/>}
                      />
                      <Route
                        path="/back-office/logout"
                        element={<AzureLogout/>}
                      />
                    </Routes>
                  </BrowserRouter>

                  <Row className='footer'>
                    <Col className='col-6 pb-5'><p className='m-0'>COPYRIGHT © {new Date().getFullYear()} PANDORA</p></Col>
                    <Col className='col-6' ><p className='m-0' style={{float: 'right'}}> Powered by: <strong>Manu Tech Innovation</strong></p></Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}

          {authUser?.isAdmin && (
            <div className='back-office-app' >
              <Row>
                <LeftSidebar isBarOpen={isBarOpen}/>

                <Col className={`right-content ${isBarOpen ? 'col-10' : 'col-12'}`}>
                  <NavBar isBarOpen={isBarOpen} setIsBarOpen={setIsBarOpen}/>

                  <BrowserRouter>
                    <Routes>
                      {/* NOTE: add routes here */}
                      <Route
                        path="/back-office"
                        element={<Dashboard />}
                      />
                      <Route
                        path="/back-office/dashboard"
                        element={<Dashboard />}
                      />
                      <Route
                        path="/back-office/product"
                        element={<Product />}
                      />
                      <Route
                        path="/back-office/sale-order"
                        element={<SaleOrder />}
                      />
                      <Route
                        path="/back-office/sync-logs"
                        element={<SyncLog />}
                      />
                      <Route
                        path="/back-office/setting"
                        element={<Setting />}
                      />
                      <Route
                        path="/back-office/logout"
                        element={<AzureLogout/>}
                      />
                    </Routes>
                  </BrowserRouter>

                  <Row className='footer'>
                    <Col className='col-6 pb-5'><p className='m-0'>COPYRIGHT © {new Date().getFullYear()} PANDORA</p></Col>
                    <Col className='col-6' ><p className='m-0' style={{float: 'right'}}> Powered by: <strong>Manu Tech Innovation</strong></p></Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </>
      )}
    </>
  );
};
