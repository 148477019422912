import {createSlice} from '@reduxjs/toolkit';

const initialState = {};

export const appSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      return {
        ...state,
        auth: action.payload,
      };
    },
  },
});

export const {setAuth} = appSlice.actions;
export default appSlice.reducer;
